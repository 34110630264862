import React from 'react';
import NumberFormat from 'react-number-format';

const AmountFormat = ({ value, top = -9, ...rest }) => {
    return (
        <>
            <span style={{ height: '100px', position: 'relative', marginRight: '10px' }}>

                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} decimalScale={0}  {...rest} />

                <span style={{ marginLeft: '.3rem' }}>VND</span>
            </span>

        </>
    )
}
export default AmountFormat