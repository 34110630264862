import React,{ useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  FormControl,
  Divider,
  FormHelperText,
  InputLabel,
  useMediaQuery,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import viLocale from 'date-fns/locale/vi';
import DateFnsUtils from '@date-io/date-fns';
import { useLanguage } from "../../hooks/languageContext";
import Header from "../Header";
import Footer from "../Footer";
import ThemeLoader from "../../utils/Loader";
import SomethingWentWrong from "../../screens/error/SomethingWentWrong";
import { useState } from "react";

import {
  optionGender,
  optionGenderVn,
  optionMarital,
  optionMaritalVn,
  yearListEn,
} from "../../static";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addForm1BValidation } from "../../utils/validation";
import { getUserData, getToken } from "../../utils/utils";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import ThemeSelectWithSelect from "../../components/formElements/ThemeSelectWithSelect";
import ThemeInput from "../../components/formElements/ThemeInput";
import ThemeInputMobile from "../../components/formElements/themeInputMobile";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Text from "../../components/typography/TextTypography";
import { updateForm1BDetails } from "../../request";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import NewFooter from "../NewFooter";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getMemberDetails, updateForm1ADetails,getForm1BUserInfo } from "../../request";
import { useTheme } from "@material-ui/core";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import DOBSelect from "../../components/formElements/DOBSelect";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ThemeAlert from "../ThemeAlert";

function Form1b() {
  
  const history = useHistory();
  const [requestPending, setRequestPending] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const query = new URLSearchParams(location.search);
  const { lang, langContent } = useLanguage();
  const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const dateObj = yearListEn(lang);
  const yearArray = dateObj.year;
  const dayArray = dateObj.day;
  const monthArray = dateObj.month;
  const redirected = query.get('redirected')
  const memberId = query.get("memberID");
  const userId = getUserData("user_id");
  const token = getToken();
  const [expanded, setExpanded] = useState(true);
  const [direct_billing_service, setDirectBillingService] = useState("");
  const [MemberDetails,setMemberDetails] =useState([])

  const [primary_policy_holder, setPrimaryPolicyHolder] = useState("");
  const [secondary_policy_holder, setSecondaryPolicyHolder] = useState("");
  const [acceptTerm,setAcceptTerm] = useState(false)
  const [dobDay, setDobDay] = useState('');
  const [dobMonth, setDobMonth] = useState('');
  const [dobYear, setDobYear] = useState('');
  const [gender, setGender] = useState('');
  const [patientName, setPatientName] = useState('');
  const [dob, setDob] = useState('');
  const [memberEmail, setMemberEmail] = useState('');
  const [marital_status, setMaritalStatus] = useState('');
  const [age, setAge] = useState('');
  const [socialSecurityNo, setSocialSecurityNo] = useState('');
  const [primaryInsuranceCardNo, setPrimaryInsuranceCardNo] = useState('');
  const [secondaryInsuranceCardNo, setSecondaryInsuranceCardNo] = useState('');
  const [patientParentName1,setPatientParentName1] = useState('')
  const [patientParentName2,setPatientParentName2] = useState('')
  const [patientParentSign1,setPatientParentSign1] = useState('')
  const [patientParentSign2,setPatientParentSign2] = useState('')
  const [patientName7yr,setPatientName7yr] = useState('')
  const [signedDate,setSignedDate] = useState('')
  const [date, setDate] = useState(new Date());
  
  const [tncAnsArray,setTncAnsArray] = useState([1,1,1,0])
  const [tempArray,setTempArray]=useState([])

  const [tncArray,setTncArray] = useState([
    {
      content:langContent.in_case_of_request,
      isChecked:false,
    },
    {
      content:langContent.i_fully_understand_and_commit,
      isChecked:false,
    },
    {
      content:langContent.by_signing_below_i_hereby,
      isChecked:false,
    },
  
  ]);

  useEffect(()=>{
    let values = [
      {
        content:langContent.in_case_of_request,
        isChecked:false,
      },
      {
        content:langContent.i_fully_understand_and_commit,
        isChecked:false,
      },
      {
        content:langContent.by_signing_below_i_hereby,
        isChecked:false,
      },
     
    ];
    setTncArray(values);
    getForm1BUserInfo(userId, lang, memberId, token, setMemberDetails, setIsLoading, setResponseError)
  },[lang]);

  useEffect(()=>{
    setDob(MemberDetails?.member_dob)
    setDobDay(MemberDetails?.member_dob_day)
    setDobMonth(MemberDetails?.member_dob_month)
    setDobYear(MemberDetails?.member_dob_year)
    setGender(MemberDetails?.member_gender)
    setPatientName(MemberDetails?.member_fname)
    setMemberEmail(MemberDetails?.member_email)
    setMaritalStatus(MemberDetails?.member_married_status)
    setSocialSecurityNo(MemberDetails?.social_security_no)
    setPrimaryInsuranceCardNo(MemberDetails?.pri_insurance_card_no)
    setSecondaryInsuranceCardNo(MemberDetails?.sec_insurance_card_no)
    setPrimaryPolicyHolder(MemberDetails?.primary_policy_holder ?? "")
    setSecondaryPolicyHolder(MemberDetails?.secondary_policy_holder)
    setPatientParentName1(MemberDetails?.patient_parent_name_1)
    setPatientParentName2(MemberDetails?.patient_parent_name_2)
    setPatientParentSign1(MemberDetails?.patient_parent_sign_1)
    setPatientParentSign2(MemberDetails?.patient_parent_sign_2)
    setPatientName7yr(MemberDetails?.patient_name_7yr)
    setSignedDate(MemberDetails?.date_signed ?? (signedDate))
    
    setTempArray(MemberDetails?.tnc_array);

  },[MemberDetails]);

  // useEffect(()=>{
  //   tempArray?.length && console.log(tempArray);
  //   if(tempArray?.length){
  //       let values =[...tempArray].map(Number);
  //       setTncAnsArray(values)
  //   }

  // },[tempArray])

  useEffect(()=>{
    calculateAge()
  },[dobDay,dobMonth,dobYear])

  const getDay = () => {
    return date.toLocaleString('en-US', { day: '2-digit' });
  };

  const getMonth = () => {
    return date.toLocaleString('en-US', { month: '2-digit' });
  };

  const getYear = () => {
    return date.getFullYear();
  };

  useEffect(()=>{
    setSignedDate(getDay()+'-'+getMonth()+'-'+getYear())
  })

  const calculateAge = () => {
    let udobDate = (dobYear+'-'+dobMonth+'-'+dobDay).toString();
    const dobDate = new Date(udobDate);
    const today = new Date();
      const birthDateObj = new Date(dobDate);
      let age = today.getFullYear() - birthDateObj.getFullYear();
      const monthDiff = today.getMonth() - birthDateObj.getMonth();

      // If birth month is after current month or birth day is after current day, subtract 1 from age
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
      }

      // return age;
    setAge(age);
  };

  const handleCheckboxChange=(e,index)=>{
    const values = [...tncArray];
    values[index].isChecked = e.target.checked;
    setTncArray(values);
    if(e.target.checked ==true){
      const temp = [...tncAnsArray]
      temp[index] =1;
      setTncAnsArray(temp)
    }else{
      const temp = [...tncAnsArray]
      temp[index] =0;
      setTncAnsArray(temp)
    }
  }

  useEffect(()=>{
    if(tncAnsArray?.length){
      tncArray.forEach((item,index)=>{
        if(tncAnsArray[index]==1){
          item.isChecked = true
        }else{
          item.isChecked=false;
        }
      });
      const values = [...tncArray];
      setTncArray(values)
    }
  },[tncAnsArray])

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(addForm1BValidation),
  });
  const handleChange = (ev) => {
    console.log(ev.target.value);
    setDirectBillingService(ev.target.value);
  };
  
  
  const onSubmit = (data) => {
    setIsLoading(true);
    console.log(data);
    console.log(direct_billing_service);
    // console.log(data.request_for_social_certificate);
    const memberDob =
      data.member_dob_year +
      "/" +
      data.member_dob_month +
      "/" +
      data.member_dob_day;
    const consent_date =
      data.member_consent_year +
      "/" +
      data.member_consent_month +
      "/" +
      data.member_consent_day;
    data.country_code = "+84";
    const fd = new FormData();
    console.log(data);
    // fd.append("user_id", userId);
    fd.append("member_id", memberId);
    fd.append("patient_name", data.patient_name ?? '');
    fd.append("dob", memberDob ?? '');
    fd.append("age", data.age ?? '');
    fd.append("gender", data.member_gender ?? '');
    fd.append("marital_status", data.member_married_status ?? '');
    fd.append("social_security_no", data.social_security_number ?? '');
    fd.append("pri_insurance_card_no", data.pri_insurance_card_no ?? '') ;
    fd.append("primary_policy_holder", data.primary_policy_holder ?? '');
    fd.append("sec_insurance_card_no", data.sec_insurance_card_no ?? '');
    fd.append("secondary_policy_holder", data.secondary_policy_holder ?? '');
    fd.append("patient_parent_sign_1", data.patient_parent_sign_1 ?? '');
    fd.append("patient_parent_name_1", data.patient_parent_name_1 ?? '');
    fd.append("patient_parent_sign_2", data.patient_parent_sign_2 ?? '');
    fd.append("patient_parent_name_2", data.patient_parent_name_2 ?? '');
    fd.append("tnc_array",tncAnsArray ?? '');
    fd.append("patient_name_7yr",patientName7yr ?? '');
    fd.append("date_signed",signedDate ?? '');

    console.log(fd);
    updateForm1BDetails(
      fd,
      token,
      setIsLoading,
      langContent,
      history,
      setRequestPending,
      redirected,
      memberId
    );
  };
  const skipForm1b = (ev) => {
    if(redirected == 'yes'){
      history.push("/cart");
    }else{
      history.push('/my_forms')
    }
  };

  const handleAcceptTermChange =() =>{
    setAcceptTerm(!acceptTerm)
  }

  return (
      <React.Fragment>
        <Header shouldHaveBackButton={true} shouldHaveSearchBar={false} />
        {isLoading ? (
          <ThemeLoader />
        ) : responseError === true ? (
          <SomethingWentWrong />
        ) : (
          <Container>
            <Box textAlign="center" mt={6}>
              <Typography variant="h2" component="h2">
                {langContent.fill_form1b}
              </Typography>
            </Box>
              <Box>
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                  <Button variant="contained" color="primary" onClick={skipForm1b} style={{ color: "white",width:'95px',marginBottom:'1rem' }} >
                    {langContent.skip}
                  </Button>
                  <ThemeAlert severity={'info'} content={langContent.if_you_skip} />
                  </Grid>
                </Grid>
              </Box>
            <Box
              pt={matchesSMDown ? 2 : 4.7}
              // pb={matchesSMDown ? 2 : 3}
              // pl={matchesSMDown ? 2 : 3}
              // pr={matchesSMDown ? 2 : 3}
            >
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Accordion expanded={true} >
                  <AccordionSummary
                    expandIcon={""}
                    aria-controls="panel1a-content"
                    id=""
                  >
                    <Typography variant="h4" style={{paddingLeft:'1rem'}}>
                      I. {langContent.patient_inforamtion}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box mb={5}>
                      <Grid container>
                        <Grid item md={12} sm={12}>
                          {langContent.your_provided_info_below_will}
                        </Grid>
                        <Grid item md={12} sm={12} style={{marginTop:'12px'}}>
                          {langContent.please_complete_the_info_in_capital_letter}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Grid container spacing={matchesSMDown ? 3 : 1}>
                        <Grid item md={6} sm={6} xs={12}>
                          <Grid
                            item
                            md={6}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="patient_name">
                              {langContent.patient_name}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl
                            fullWidth
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <ThemeInput
                              size="small"
                              type="text"
                              name="patient_name"
                              id="patient_name"
                              register={register}
                              errors={errors}
                              fullWidth
                              value={patientName ?? ''}
                              onChange={e=>{setPatientName(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Grid
                            item
                            md={12}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="member_married_status">
                              {langContent.marital_status}{" "}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl
                            fullWidth
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <ThemeSelectWithSelect
                              name="member_married_status"
                              id="member_married_status"
                              size="small"
                              register={register}
                              errors={errors}
                              fullWidth
                              optionObj={
                                lang === "en" ? optionMarital : optionMaritalVn
                              }
                              value={marital_status ?? ''}
                              onChange={e=>{setMaritalStatus(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          md={3}
                          sm={12}
                          xs={12}
                          style={{
                            marginTop: ".7rem",
                            marginBottom: ".3rem",
                          }}
                        >
                          <Grid item md={6} sm={12}>
                            <InputLabel htmlFor="member_gender">
                              {langContent.sex}{" "}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl  fullWidth
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}>
                            <ThemeSelectWithSelect
                              name="member_gender"
                              id="member_gender"
                              register={register}
                              size="small"
                              errors={errors}
                              fullWidth
                              optionObj={
                                lang === "en" ? optionGender : optionGenderVn
                              }
                              value={gender ?? ''}
                              onChange={e=>{setGender(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                        
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={matchesSMDown ? 3 : 1}>
                        <Grid item container md={6} sm={12} xs={12}>
                          <Grid
                            item
                            md={6}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="user_dob">
                              {langContent.date_of_birth}{" "}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item md={4} sm={4} xs={4}>
                              <FormControl fullWidth>
                                <DOBSelect
                                  size="small"
                                  name="member_dob_day"
                                  label={langContent.day}
                                  register={register}
                                  errors={errors}
                                  valueKey="key"
                                  fullWidth
                                  optionObj={dayArray}
                                  labelKey="label"
                                  selectLabel="select"
                                  value={dobDay ?? ''}
                                  onChange={e=>{setDobDay(e.target.value)}}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} sm={4} xs={4}>
                              <FormControl fullWidth>
                                <ThemeSelectWithSelect
                                  size="small"
                                  name="member_dob_month"
                                  label={langContent.month}
                                  register={register}
                                  errors={errors}
                                  valueKey="key"
                                  fullWidth
                                  optionObj={monthArray}
                                  labelKey="label"
                                  value={dobMonth ?? ''}
                                  onChange={e=>{setDobMonth(e.target.value)}}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} sm={4} xs={4}>
                              <FormControl fullWidth>
                                <ThemeSelectWithSelect
                                  size="small"
                                  name="member_dob_year"
                                  label={langContent.year}
                                  register={register}
                                  errors={errors}
                                  valueKey="key"
                                  fullWidth
                                  optionObj={yearArray}
                                  labelKey="label"
                                  value={dobYear ?? ''}
                                  onChange={e=>{setDobYear(e.target.value)}}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Grid
                            item
                            md={12}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="age">
                              {langContent.age}{" "}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl
                            fullWidth
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                           <ThemeInput
                              size="small"
                              type="text"
                              name="age"
                              id="age"
                              register={register}
                              errors={errors}
                              fullWidth
                              value={age ?? ''}
                              onChange={e=>{setAge(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={3} xs={12}>
                          <Grid
                            item
                            md={12}
                            sm={12}
                            style={{
                              marginTop: ".3rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="social_security_number">
                              {langContent.social_security_number}{" "}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}>
                            <ThemeInput
                              size="small"
                              id="social_security_number"
                              name="social_security_number"
                              register={register}
                              errors={errors}
                              fullWidth
                              isRequired={true}
                              value={socialSecurityNo ?? ''}
                              onChange={e=>{setSocialSecurityNo(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid spacing={2} container></Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                {/* Second Part */}
                <Accordion style={{ marginTop: "1rem" }} expanded={true}>
                  <AccordionSummary
                    expandIcon={""}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h4" style={{paddingLeft:'1rem'}}>
                      II. {langContent.health_insurance_information}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box  mt={2}>
                      <Grid container spacing={matchesSMDown ? 3 : 1}>
                        <Grid item sm={6} xs={12}>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="pri_insurance_card_no">
                              {langContent.primary_insurance}{" "}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInput
                              size="small"
                              id="pri_insurance_card_no"
                              name="pri_insurance_card_no"
                              register={register}
                              errors={errors}
                              fullWidth
                              value={primaryInsuranceCardNo ?? ''} 
                              onChange={e=>{setPrimaryInsuranceCardNo(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                         
                        <Grid item sm={6} xs={12}>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel
                              htmlFor="patient_is_subscriber"
                              style={{ marginLeft: "12px" }}
                            >
                              {langContent.patient_is_subscriber}{" "}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth style={{ marginLeft: "12px" }}>
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue=""
                              name="primary_policy_holder"
                              value={primary_policy_holder ?? null}
                              onChange={(ev) =>
                                setPrimaryPolicyHolder(ev.target.value)
                              }
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio size="small" inputRef={register} />
                                }
                                label={langContent.yes}
                              />
                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio size="small" inputRef={register} />
                                }
                                label={langContent.no}
                              />
                            </RadioGroup>
                            <FormHelperText style={{ color: "tomato" }}>
                              {errors["primary_policy_holder"]?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider variant="middle" style={{marginTop:'1rem'}} />
                    {/* secondary insurance card details */}
                    <Box  mt={2}>
                      <Grid container spacing={matchesSMDown ? 3 : 1}>
                        <Grid item sm={6} xs={12}>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="sec_insurance_card_no">
                              {langContent.secondary_insurance}{" "}
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInput
                              size="small"
                              id="sec_insurance_card_no"
                              name="sec_insurance_card_no"
                              register={register}
                              errors={errors}
                              fullWidth
                              value={secondaryInsuranceCardNo ?? ''} 
                              onChange={e=>{setSecondaryInsuranceCardNo(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel
                              htmlFor="secondary_policy_holder"
                              style={{ marginLeft: "12px" }}
                            >
                              {langContent.patient_is_subscriber}{" "}
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth style={{ marginLeft: "12px" }}>
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue=""
                              name="secondary_policy_holder"
                              value={secondary_policy_holder ?? ''}
                              onChange={(ev) =>
                                setSecondaryPolicyHolder(ev.target.value)
                              }
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio size="small" inputRef={register} />
                                }
                                label={langContent.yes}
                              />
                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio size="small" inputRef={register} />
                                }
                                label={langContent.no}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Box mt={2}>
                  <Accordion expanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h4" style={{paddingLeft:'1rem'}}>
                        III. {langContent.terms_and_conditions}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Container>
                      <BrowserView>
                      <Grid item md={12} xs={12} style={{marginBottom:'1rem'}}>
                           <b>{langContent.terms_conditions_listed_below}</b>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{width:'85%',textAlign:'center'}}> <h3>{langContent.Contents}</h3> </TableCell>
                                    <TableCell align="center">{langContent.check_the_box_if_agree}</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {tncArray.map((row,index) => (
                                  <TableRow
                                      key={index}
                                    >
                                      <TableCell component="th" scope="row">
                                        {row.content}
                                      </TableCell>
                                      <TableCell align="center" >
                                        <Checkbox checked={row.isChecked} value={row.isChecked}  onClick={(e)=>handleCheckboxChange(e,index)} />
                                      </TableCell>
                                    </TableRow>
                                ))}

                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                          <Box mt={3}>
                                <Grid container>
                                  <Grid item md={12} lg={12} sm={12}>
                                    <p>{langContent.i_assure_that_i_have_}
                                      <Checkbox style={{float:'right',marginRight:'4rem'}} checked={acceptTerm}  value={acceptTerm} onClick={(e)=>handleAcceptTermChange(e)}/>
                                    </p>
                                  </Grid>
                                </Grid>
                          </Box>
                      </BrowserView>
                      <MobileView>
                      <Grid item md={12} xs={12}>
                              <List component="nav" dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                  <ListItem
                                      disablePadding
                                      disableGutters={true}
                                    >
                                      <ListItemButton>
                                        <ListItemText  primary={langContent.terms_conditions_listed_below} />
                                      </ListItemButton>
                                    </ListItem>
                                {tncArray.map((row,index) => {
                                  const labelId = `checkbox-list-secondary-label-${index}`;
                                  return (
                                    <ListItem
                                      key={index}
                                      secondaryAction={
                                        <Checkbox checked={row.isChecked} value={row.isChecked}  onClick={(e)=>handleCheckboxChange(e,index)} />

                                      }
                                      disablePadding
                                      disableGutters={true}
                                    >
                                      <ListItemButton>
                                        <ListItemText id={labelId} primary={row.content} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </Grid>
                            <Box mt={3}>
                                <Grid container>
                                  <Grid item md={12} lg={12} sm={12}>
                                    <p>
                                      <Checkbox checked={acceptTerm}  value={acceptTerm} onClick={(e)=>handleAcceptTermChange(e)}/>
                                      {langContent.i_assure_that_i_have_}
                                    </p>
                                  </Grid>
                                </Grid>
                          </Box>
                      </MobileView>
                         
                        </Container>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={true}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h4" style={{paddingLeft:'1rem'}}>
                        IV. {langContent.patinet_parent_or_guardian_sign}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box mt={2}>
                        <Grid container spacing={matchesSMDown ? 3 : 1}>
                          <Grid item sm={6} xs={12}>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              sm={12}
                              style={{
                                marginTop: ".5rem",
                                marginBottom: ".3rem",
                              }}
                            >
                              <InputLabel htmlFor="patient_parent_guardian_name">
                                {langContent.signature1}
                                {/* <span style={{ color: "tomato" }}>*</span> */}
                              </InputLabel>
                            </Grid>
                            <FormControl fullWidth>
                              <ThemeInput
                                size="small"
                                id="patient_parent_guardian_name"
                                name="patient_parent_sign_1"
                                register={register}
                                errors={errors}
                                fullWidth
                                value={patientParentSign1 ?? ''} 
                              onChange={e=>{setPatientParentSign1(e.target.value)}}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              sm={12}
                              style={{
                                marginTop: ".5rem",
                                marginBottom: ".3rem",
                              }}
                            >
                              <InputLabel htmlFor="patient_parent_guardian_name">
                                {langContent.signature2}
                                {/* <span style={{ color: "tomato" }}>*</span> */}
                              </InputLabel>
                            </Grid>
                            <FormControl fullWidth>
                              <ThemeInput
                                size="small"
                                id="patient_parent_guardian_name"
                                name="patient_parent_sign_2"
                                register={register}
                                errors={errors}
                                fullWidth
                                value={patientParentSign2 ?? ''} 
                                onChange={e=>{setPatientParentSign2(e.target.value)}}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* <Box mt={2}>
                        <Grid container spacing={matchesSMDown ? 3 : 1}>
                          <Grid item sm={6} xs={12}>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              sm={12}
                              style={{
                                marginTop: ".5rem",
                                marginBottom: ".3rem",
                              }}
                            >
                              <InputLabel htmlFor="patient_parent_guardian_name">
                                {langContent.full_name}{""}
                                <span style={{ color: "tomato" }}>*</span>
                              </InputLabel>
                            </Grid>
                            <FormControl fullWidth>
                              <ThemeInput
                                size="small"
                                id="full_name_"
                                name="patient_parent_name_1"
                                register={register}
                                errors={errors}
                                fullWidth
                                value={patientParentName1 ?? ''} 
                                onChange={e=>{setPatientParentName1(e.target.value)}}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              sm={12}
                              style={{
                                marginTop: ".5rem",
                                marginBottom: ".3rem",
                              }}
                            >
                              <InputLabel htmlFor="full_name">
                                {langContent.full_name}{""}
                                <span style={{ color: "tomato" }}>*</span>
                              </InputLabel>
                            </Grid>
                            <FormControl fullWidth>
                              <ThemeInput
                                size="small"
                                id="full_name"
                                name="patient_parent_name_2"
                                register={register}
                                errors={errors}
                                fullWidth
                                value={patientParentName2 ?? ''} 
                                onChange={e=>{setPatientParentName2(e.target.value)}}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box> */}
                      <Box mt={2}>
                        <Grid container spacing={matchesSMDown ? 3 : 1}>
                          <Grid item sm={12} md={12}>
                            {/* {langContent.signature_of_the_patient_7yr} */}
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              sm={12}
                              style={{
                                marginTop: ".5rem",
                                marginBottom: ".3rem",
                              }}
                            >
                              <InputLabel htmlFor="signature_of_the_patient_7yr">
                                {langContent.full_name}{""}
                                {/* <span style={{ color: "tomato" }}>*</span> */}
                              </InputLabel>
                            </Grid>
                            <FormControl fullWidth>
                              <ThemeInput
                                size="small"
                                id="signature_of_the_patient_7yr"
                                name="signature_of_the_patient_7yr"
                                register={register}
                                errors={errors}
                                fullWidth
                                value={patientName7yr ?? ''} 
                                onChange={e=>{setPatientName7yr(e.target.value)}}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              sm={12}
                              style={{
                                marginTop: ".5rem",
                                marginBottom: ".3rem",
                              }}
                            >
                              <InputLabel htmlFor="date_signed">
                                {langContent.date}{""}
                                {/* <span style={{ color: "tomato" }}>*</span> */}
                              </InputLabel>
                            </Grid>
                            <FormControl fullWidth>
                              <ThemeInput
                                size="small"
                                id="date_signed"
                                name="date_signed"
                                register={register}
                                errors={errors}
                                fullWidth
                                value={signedDate ?? ''} 
                                onChange={e=>{setSignedDate(e.target.value)}}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      mt={7.8}
                      mb={2.6}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={requestPending ? true : false}
                        color="primary"
                        style={{ padding: "8px 22px", color: "white" }}
                      >
                        {requestPending && (
                          <>
                            <CircularProgress color="primary" size={22} />
                            <Text
                              variant="textxs"
                              color="#02abd6"
                              style={{ marginLeft: "10px" }}
                            >
                              {langContent.loading}..
                            </Text>
                          </>
                        )}
                        {!requestPending && langContent.submit_form}
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </form>
            </Box>
          </Container>
        )}
        {!isLoading && <NewFooter  />}
      </React.Fragment>
  );
}

export default Form1b;
