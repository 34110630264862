import { Box, makeStyles, useMediaQuery,Container, useTheme, alpha, Chip, Card, Grid, Dialog, DialogTitle, DialogContent, FormControl, FormHelperText, DialogActions, Button, CircularProgress } from '@material-ui/core'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import Header from '../../components/Header'
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import { addProductToCartApi, getHospitalPackageList, getFilteredPackageListPagination, getMembersList, addHealthPackage, getHealthPackageTimeSlots,getFilteredProductListPagination } from '../../request';
import { useLanguage } from '../../hooks/languageContext';
import ThemeLoader from '../../utils/Loader';
import { getToken, getUserData } from '../../utils/utils';
import '../../styles/style.css'
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import CallOptionComponent from '../../components/CallComponent';
import Text from '../../components/typography/TextTypography';
import { SearchProductComponent } from '../../components/Filter/SearchComponent';
import _ from 'lodash'
import SomethingWentWrong from '../error/SomethingWentWrong';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import viLocale from 'date-fns/locale/vi';
import DateFnsUtils from '@date-io/date-fns';
import ThemeSelectWithSelect from '../../components/formElements/ThemeSelectWithSelect';
import NewFooter from '../../components/NewFooter';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import cartIcon from '../../assets/icons/cartIcon.svg'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SecondaryButtonComponent from '../../components/SecondaryButtonComponent';
import lost from '../../assets/img/lost.jpeg';
// import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';
// import uuid from 'uuid';
import { v4 as uuidv4 } from 'uuid';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import DialogContentText from '@mui/material/DialogContentText';
import { Badge, IconButton } from '@mui/material';
import SaleLabel from "../../assets/img/sale label.png";
import AddIcon from '@mui/icons-material/Add';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        border: '1px solid grey',
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    /** 
     * ! This sets the inner width of the input search field
     */
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    fullWidthInput: {
        padding: theme.spacing(1, 2, 1, 1),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
}))


const ProductList = () =>{
    const history = useHistory()
    const { lang, langContent,  cartCount,setCartCount } = useLanguage()
    const [isLoading, setIsLoading] = useState(true)
    const [packageList, setPackageList] = useState([])
    const theme = useTheme()
    const classes = useStyles();
    const matchesUpSM = useMediaQuery(theme.breakpoints.up("sm"));
    const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"))
    const [searchValue, setSearchValue] = useState('')
    const [searchField, setSearchField] = useState('')
    const token = getToken()
    const userId = getUserData('user_id')
    const userMobile = getUserData('user_mobile')
    const [hospital, setHospital] = useState({})
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const clinicId = query.get('clinic')
    const [responseError, setResponseError] = useState(false)
    const [page, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [packageListNew, setpackageListNew] = useState([]);
    const [loader, setLoader] = useState(false);
    const { handleSubmit: searchSubmit, register: register2 } = useForm();
    const observer = useRef();
    const [productList,setProductList] = useState([])
    // const [cookies, setCookie, removeCookie] = useCookies(['session-id']);
    const cookies = new Cookies(null, { path: '/' });
    const [ismodalOpen,setIsModalOpen] = useState(false)
    const [cartMessage,setCartMessage] = useState('')
    const [deleteCart,setDeleteCart] = useState( 'no')
    const [currentClickedProduct,setCurrentClickedProduct] = useState([])
    const formRef = useRef()
    const [requestPending, setRequestPending] = useState(false)

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPage => prevPage + 1)
                setLoader(true)
            }
        })
        if (node) observer.current.observe(node)
    }, [hasMore]);

    useEffect(() => {
            getFilteredProductListPagination(setProductList, lang, setIsLoading, page, setHasMore,setLoader)
            }, [page, hasMore, lang])

    const onSearchSubmit = (data, e) => {
        e.preventDefault()
        const searchField = data.search_field
        setSearchValue(data.searchField)
        setPageNumber(0);
        setpackageListNew([])
        setPackageList([])
        const updates = packageListNew.filter((data) => data.nice_name.includes(searchField));
        setpackageListNew(updates);
    }
    const handleClickOpen = () => {
        setIsModalOpen(true);
      };
    
      const handleClose = () => {
        setIsModalOpen(false);
      };
      const userCartConfirmation = () => {
        setIsModalOpen(false);
        setDeleteCart('yes')
        setTimeout(() => {
        }, 1000);
        // userCartConfirm(fd, history, token, langContent, setIsLoading, setRequestPending,data.member_id,setIsModalOpen,setCartMessage)
      };
      
      useEffect(()=>{
        if(deleteCart=='yes'){
            // formRef.current.requestSubmit()
            addProductToCart(currentClickedProduct)
        }
      },[deleteCart])
    const addProductToCart = (product) => {

            console.log(product);
            console.log("token",token);
            if(cookies.get('session_id')===undefined && token==false){
                const uniqueRandomString = uuidv4();
                cookies.set('session_id', uniqueRandomString);
                console.log(uniqueRandomString);
                console.log(cookies.get('session_id'));

            }else{
                console.log(cookies.get('session_id'));
            }
            const userSession = cookies.get('session_id')
            setCurrentClickedProduct(product)
            addProductToCartApi(lang,langContent, setIsLoading,product,userSession,setCartCount,userId,deleteCart,setIsModalOpen,setRequestPending,setCartMessage,history)
            
            // localStorage.setItem('healthPackage', JSON.stringify(healthPackage))
            // setIsAddPackageDialogueOpen(true)
            // history.push(`/book_package/${hospital.dr_clinic_nice_name}?clinic=${healthPackage.dr_clinic_id}&package=${healthPackage.labtest_id}`)

        
    }
    return (
    <>
    <Header shouldHaveBackButton={true} shouldHaveSearchBar={false} />
    <React.Fragment>
        <Box ml={2.5} mr={2.5} mb={isLoading ? 4 : 0}>
            <Box mt={matchesSMDown ? 2.5 : 6.5} mb={2.5}>
                <Text variant="textmd" fontWeight={600}>
                                { langContent.select_product}
                </Text>
            </Box>
            <Box display="flex">
                <Box width={matchesSMDown ? '100%' : '35%'}>
                    <SearchProductComponent register={register2} submitHandler={searchSubmit} onSearchSubmit={onSearchSubmit} isLoading={isLoading} />
                </Box>
            </Box>
        </Box>
        <Box mt={3}>
            <Grid container>
                <Grid item sm={12} lg={12} md={12}>
                {
                    isLoading ? <ThemeLoader /> :
                        (isLoading === false)  && responseError === true ?
                            <SomethingWentWrongProduct /> :
                            <Box style={{ minHeight: '56vh' }} ml={2.5} mr={2.5}>
                                <Box mt={matchesUpSM ? 1 : 0} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
                                    {
                                        _.isEmpty(hospital.clinic_image_url) ? null :
                                            <Box border="1px solid #E5E5E5" style={{ padding: '10px 30px', borderRadius: '10px' }}>
                                                <img src={hospital.clinic_image_url} alt="Hospital Logo" height={100} style={{ objectFit: 'contain', maxWidth: 200 }} loading="lazy" />
                                            </Box>
                                    }
                                    <Box display="flex" justifyContent="center" flexDirection="column" textAlign="center" mb={4} mt={3}>
                                        <Typography variant="h1" fontWeight={600}>
                                            {lang === 'en' ? hospital.dr_clinic_detail : hospital.ln_dr_clinic_detail}
                                        </Typography>
                                        <Typography style={{ marginBottom: '1rem' }} variant="h5">
                                            {hospital.city_name}
                                        </Typography>
                                    </Box>
                                    {
                                        productList && productList.length === 0 ?
                                            <Box p={2} component={Card} mt={10}>
                                                <Typography variant="h6" style={{ color: 'red' }}>
                                                    {langContent.sorry_no_products}
                                                </Typography>
                                            </Box>
                                            :
                                            <Container  maxWidth="xl">
                                                <Grid container spacing={matchesSMDown ? 0 : 4}>
                                                    {
                                                        productList && productList.map((product, index) => (
                                                                <Grid spacing={3} ref={lastBookElementRef}  item key={index} xs={12} sm={12} md={6} lg={3} xl={3}  style={{
                                                                    marginBottom: matchesSMDown ?
                                                                        '2rem' : 0
                                                                }}>
                                                                    <ProductCard key={index} {...{
                                                                        product, index, langContent, history,  token, setIsLoading,addProductToCart
                                                                    }} />
                                                                </Grid>
                                                        ))
                                                    }
                                                    {loader && (
                                                        <Grid item md={6} xl={3} lg={4} sm={12} xs={12} justifyContent='center'>
                                                            <ThemeLoader />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Container>
                                           
                                    }
                                    {/* <BookPackageDialogComponent {...{ isAddPackageDialogueOpen, setIsAddPackageDialogueOpen, submitHandler: handleSubmit, onSubmit: onPackageAddToCart, selectedDate, register, errors, healthPackageTimeSlots: timeSlots, memberList, setSelectedDate, setValue, isLoading }} /> */}
                                </Box>
                            </Box>
                }
                </Grid>
            </Grid>
        </Box>
        <Dialog
            open={ismodalOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Cart Message"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {cartMessage}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={userCartConfirmation} autoFocus>
                Yes
            </Button>
            </DialogActions>
        </Dialog>
         <NewFooter />

    </React.Fragment>
    </>
    )
}
export default ProductList

// export  const  ProductCard =({product, index, langContent, history,  token, setIsLoading,addProductToCart}) => {
//     const addNoImage = (ev) =>{
//         ev.target.src ='https://fact.co.in/images/NoImage.png';
//     }
//     return (
//       <Card sx={{ maxWidth: 345 }}>
//         <CardMedia
//           component="img"
//           alt="green iguana"
//           height="180"
//           image={product.featured_img}
//           onError={ev => {addNoImage(ev)}}
//           style={{objectFit:'contain',paddingTop:'1rem'}}
//         />
//         <CardContent>
//           <Typography gutterBottom variant="h5" noWrap component="div">
//             {product.name}
//           </Typography>
//           <Typography gutterBottom variant="h6" component="h6" color='secondary'>
//             {product.selling_price} VND
//           </Typography>
//         </CardContent>
//         <CardActions sx={{padding:0}}>
//             <Box display="flex" className="bottomRadiusCard" style={{width:'100%'}}>
//                 <Box p={2} width="50%" display="flex" justifyContent="center" bgcolor="#2BB2B4" color="primary" style={{ cursor: 'pointer' }} onClick={() => addProductToCart(product)}>
//                     <Box mr={2}>
//                         <img src={cartIcon} alt="" />
//                     </Box>
//                     <Box mt={.2}>
//                         <Text variant="textsm" color='#fff' >

//                             {langContent.add_to_cart}
//                         </Text>

//                     </Box>
//                 </Box>
//                 <Box width="50%" component={Button} display="flex" alignItems="center" justifyContent="center" bgcolor="rgba(4, 180, 227, 0.22)" style={{ cursor: 'pointer' }} onClick={() => history.push(`/product_detail?productId=${product.item_id}`)}>
//                     <Box >

//                         <Text variant="textsm" color="#4F4F4F" fontWeight={500}>
//                             {langContent.know_more}
//                         </Text>
//                     </Box>
//                     <Box mt={.2}>
//                         <ChevronRightIcon style={{ color: '#fff', fontSize: '2rem' }} />
//                     </Box>
//                 </Box>
//             </Box>
//         </CardActions>
//       </Card>
//     );
//   }
  export const ProductCard = ({product, index, langContent, history,  token, setIsLoading,addProductToCart}) => {
        const addNoImage = (ev) =>{
        ev.target.src ='https://fact.co.in/images/NoImage.png';
    }
    return (
        <Card style={{  borderRadius: '8px', boxShadow: 3, overflow: 'hidden', position: 'relative' }}>
            {/* Sale Badge */}
            <img src={SaleLabel} alt="" style={{ position: 'absolute', top: 0, left: 1, zIndex: 1,width:'42px' }} />

            {/* Image */}
            <CardMedia
                component="img"
                image={product.featured_img}
                alt="Product Image"
                onError={ev => {addNoImage(ev)}}
                sx={{ height: 240, objectFit: 'cover' }}
            />

            {/* Content */}
            <CardContent sx={{ p: 2 }}>
                <Typography variant="h6" style={{fontWeight:'600'}} noWrap component="div" sx={{ mb: 1 }}>
                    {product.name}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', mb: 1 }}>
                    <div>

                    </div>
                    <div style={{textAlign:'end'}}>
                    {/* <Typography variant="body2" color="text.secondary" style={{ textDecoration: 'line-through' }}>
                        {product.price}đ
                    </Typography> */}
                    <Typography variant="h5" color="error" fontWeight="bold">
                        {product.selling_price}đ
                    </Typography>
                    </div>
                   
                </Box>
                <Button variant="text" style={{float:'right'}}  sx={{ p: 0, color: 'text.secondary', textTransform: 'none', }} onClick={() => history.push(`/product_detail?productId=${product.item_id}`)}>
                   More Details <ChevronRightIcon/>
                </Button>
            </CardContent>

            {/* Add to Cart Button */}
            <Box style={{ position: 'absolute', bottom: 0, left: 0, padding:'0.7em', backgroundColor: 'teal', borderTopRightRadius: '8px' }} onClick={() => addProductToCart(product)}>
                <IconButton style={{ color: 'white',padding:'0px' }}>
                    <ShoppingBagOutlinedIcon />
                    <AddIcon style={{height:'0.7em',marginLeft:'-3px'}}/>
                </IconButton>
            </Box>
        </Card>
    );
};


 export const SomethingWentWrongProduct = () => {
    const { langContent } = useLanguage()
    const theme = useTheme()
    const matchesXSDown = useMediaQuery(theme.breakpoints.down("xs"));
    const history = useHistory()
    return (
        <React.Fragment>
            <Container>
                <Box alignItems='center' width="100%">
                    <Box mb={3} mt={matchesXSDown ? 2 : 12}>
                        <Text variant="textlg" color="#02abd6">

                            {langContent.something_wrong_page_product}
                        </Text>
                        <Box textAlign='center' mt={3}>

                            <SecondaryButtonComponent color="primary" variant='contained' label={langContent.home} onClickHandler={() => history.push('/')} />
                        </Box>
                    </Box>
                    <Box >

                        <img src={lost} alt="" width="100%" />
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    )
}
