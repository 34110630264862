import { Button, makeStyles,CircularProgress } from '@material-ui/core'
import Text from '../components/typography/TextTypography';
import React from 'react'
import { useLanguage } from '../hooks/languageContext';

const useStyles = makeStyles((theme) => ({

    buttonStyle: {
        padding: '8px 22px'
    }


}));

const SecondaryButtonComponent = ({ color, variant, onClickHandler, label,requestPending, ...rest }) => {
    const classes = useStyles();
    const { lang, langContent } = useLanguage()
    

    return (
        <Button  className={classes.buttonStyle} variant={variant} color={color} style={{ textTransform: 'none', color: variant === 'contained' ? 'whitesmoke' : '#02abd6' }} {...rest} onClick={onClickHandler}>
            {
                requestPending &&
                <>
                    <CircularProgress color="inherit" size={22} />
                    <Text variant="textxs" color="white" style={{ marginLeft: '10px' }}>
                        {langContent.loading}..
                    </Text>
                </>
            }
            {
                !requestPending && label
            }
        </Button>
    )
}

export default SecondaryButtonComponent