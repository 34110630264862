/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React from 'react'
import { useState, useEffect } from 'react';
import { getSurveyQuestions } from '../request';
import ThemeLoader from '../utils/Loader';
import { Box,CircularProgress, Typography,MenuItem,Menu, useMediaQuery, Divider, Container, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Grid, TextareaAutosize } from '@material-ui/core';
import parse from 'html-react-parser';
import '../styles/style.css'
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import NewFooter from './NewFooter';
import NewHeader from './NewHeader';
import surveyBg from './../assets/img/survey_bg.jpg'
import { useLanguage } from '../hooks/languageContext';
import { Label } from '@material-ui/icons';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { saveHomeCareSurveyForm } from "../request";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import Text from "./typography/TextTypography";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { homeCareSurveyFormValidation } from "../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getUserData, getToken } from "../utils/utils";
import iqiLogo from "../assets/img/logo.png";

const useStyles = makeStyles((theme) => ({
    divider: {
        background: 'red',
        width: '2px',
        marginLeft: '10px',
        marginRight: '10px',

    }
}))
const UserHomeCareSurveyCompleted = () => {
    const { lang, langContent,setLang, } = useLanguage();
    const [isLoading, setIsLoading] = useState(false)
    const [about, setAbout] = useState(null)
    const [requestPending, setRequestPending] = useState(false);
    const [contactUs, setContactUs] = useState(null)
    const [aboutUsTitle, setAboutUsTitle] = useState(null)
    const [contactUsTitle, setContactUsTitle] = useState(null)
    const [surveyQuestions, setSurveyQuestions] = useState(null)
    const theme = useTheme()
    const classes = useStyles();
    const token = getToken();
    const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const location = useLocation()
    const urlPath = (location.pathname)
    const splitPath = urlPath.split('/')
    const completedForm = splitPath[2]
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [inputFields, setInputFields] = useState({
        question1: "",
        question2: "",
        question3: "",
        question4: "",
        question5: "",
        question6: "",
        question7: "",
        question8: "",
      });
      const [submitting, setSubmitting] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const validateValues = (inputValues) => {
        let errors = {};
        if (inputValues.question1 =="") {
          errors.question1 = "This is required field";
        }
        if (inputValues.question2 =="") {
          errors.question2 = "This is required field";
        }
        if (inputValues.question3 =="") {
          errors.question3 = "This is required field";
        }
        if (inputValues.question4 =="") {
          errors.question4 = "This is required field";
        }
        if (inputValues.question5 =="") {
          errors.question5 = "This is required field";
        }
        if (inputValues.question6 =="") {
          errors.question6 = "This is required field";
        }
        if (inputValues.question7 =="") {
          errors.question7 = "This is required field";
        }
        if (inputValues.question8 =="") {
          errors.question8 = "This is required field";
        }
      
        return errors;
      };
    const handleClose = () => {
        setOpen(false);
    };
    const languageDropdownClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const languageDropdownClose = (e) => {
      setAnchorEl(null);
      const { language } = e.currentTarget.dataset;
      if (language === undefined) {
        return;
      }
      setLang(language);
      localStorage.setItem("lang", language);
    };
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        // textAlign: 'center',
        paddingLeft:'1rem',
        paddingTop:'1rem',
        color: theme.palette.text.secondary,
      }));
      const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(homeCareSurveyFormValidation),
      });
      const onSubmit = (event) => {
        event.preventDefault();
        var data = new FormData(event.target);
        data.append('userID',userID)
        let formObject = Object.fromEntries(data.entries());
     
        console.log(Object.keys(formObject).length);
        console.log(formObject);
        console.log(userID);
        
        // return false;
        saveHomeCareSurveyForm(
          formObject,
          token,
          setIsLoading,
          langContent,
          history,
          setRequestPending
        );
      };
      const handleChange = (e) => {
        setInputFields({ ...inputFields, [e.target.name]: e.target.value });
      };
    return (
        <React.Fragment>
            {
                isLoading ? <ThemeLoader />
                    :
                  <Container>
                    <Box mt={4} mb={4}>
                        <Grid container> 
                            <Grid item md={11} sm={11}  >
                              <Grid container>
                                <Grid item md={12} sm={12} style={{display:'flex',justifyContent:'center'}}>
                                  <img src={iqiLogo} alt="" style={{width:'250px'}} />
                                </Grid>
                                <Grid item md={12} sm={12} style={{display:'flex',justifyContent:'center'}}>
                                  <Typography variant='h4' fontWeight={600}>
                                      {/* Home Care Service Survey Form */}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={1} sm={1}>
                              <Box>
                                <Button
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={languageDropdownClick}
                                  endIcon={<KeyboardArrowDownIcon />}
                                >
                                  {lang === "vn" ? "Vn" : "En"}
                                </Button>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={languageDropdownClose}
                                  style={{ minWidth: 200 }}
                                >
                                  <MenuItem data-language="en" onClick={languageDropdownClose}>
                                    English
                                  </MenuItem>
                                  <MenuItem data-language="vn" onClick={languageDropdownClose}>
                                    Tiếng Việt
                                  </MenuItem>
                                </Menu>
                              </Box>
                            </Grid>
                        </Grid>
                        <Grid container style={{height:'300px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                          <Grid item md={12} sm={12} style={{height:'300px',display:'flex',justifyContent:'center',alignItems:'center'}}> 
                            {
                              completedForm ==0 ?
                              <Typography variant='h5' color='primary'>
                                  You have successfully  completed this survey. Thank you for your response
                              </Typography>
                              :
                              <Typography variant='h5' color='primary'>
                                  You have already completed this survey. Thank you.
                              </Typography>
                            }
                              
                          </Grid>
                        </Grid>
                    </Box>
                    <Box>
                    </Box>
                  </Container>
            }
            <Box>
            </Box>
            {!isLoading && <NewFooter />}

        </React.Fragment>
    )
}
export default UserHomeCareSurveyCompleted

