import React from 'react'
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import P1 from '../../assets/img/info1.png'
import P2 from '../../assets/img/info2.png'
import P3 from '../../assets/img/info3.png'
import SecondaryButtonComponent from '../../components/SecondaryButtonComponent';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import '../../styles/style.css'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};



const AppInfo = () => {
    const theme = useTheme()
    const history = useHistory()
    const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));
    const onGetStarted = () => {
        localStorage.setItem('visited', true)
        history.push('/')
    }
    useEffect(() => {
        const visited = (localStorage.getItem('visited'))
        if (visited == 'true') {
            history.push('/')
        }
    }, [])
    const CustomDot = ({ onMove, index, onClick, active }) => {
        return (
            <li
                className={active ? "active" : "inactive"}
                onClick={() => onClick()}
                style={{ position: 'relative', bottom: 60 }}
            >
                <FiberManualRecordIcon style={{ fontSize: '13px' }} />
            </li>
        );
    };

    return (
        <React.Fragment>
            <Carousel
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={false}
                arrow={false}
                autoPlay={false}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                transitionDuration={500}
                containerClass="carousel-container-2"
                removeArrowOnDeviceType={["tablet", "mobile", "superLargeDesktop", "desktop"]}
                renderButtonGroupOutside={true}
                itemClass="carousel-card"
                customDot={<CustomDot />}
            >


                <Box height="100vh">
                    <img src={P1} alt="backgground1" style={{ width: '100%', }} />
                </Box>
                <Box height="100vh">
                    <img src={P2} alt="background2" style={{ width: '100%' }} />
                </Box>
                <Box height="100vh" style={{ background: `url(${P3})`, position: 'relative', width: '100%', objectFit: 'contain', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                    <Box style={{ position: 'absolute', bottom: '7%', left: '40%' }}>

                        <SecondaryButtonComponent label="Get Started" variant="contained" color="primary" onClickHandler={() => onGetStarted()} />
                    </Box>
                </Box>


            </Carousel>
        </React.Fragment>
    )
}
export default AppInfo