import React from 'react'
import { Typography, makeStyles } from '@material-ui/core';

const Text = ({ variant, fontWeight = 300, children, textAlign = "left", color = "#000", ...rest }) => {
    const fontSize = variant === 'textlg' ? '1.5rem' : variant === 'textmd' ? '1.125rem' : variant === 'textsm' ? '1rem' : '0.875rem'

    const useStyles = makeStyles((theme) => ({

        textStyle: {
            fontSize: fontSize,
            fontWeight: fontWeight,
            color: color,
            textAlign: textAlign,
            [theme.breakpoints.down('sm')]: {
                fontSize: variant === 'textlg' ? '1.3rem' : '1.2rem'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: variant === 'textlg' ? '1.3rem' : '1rem'
            },

        }

    }));
    const classes = useStyles();


    return (
        <Typography className={classes.textStyle} {...rest}>
            {children}
        </Typography>
    )

}
export default Text