import React from 'react'
import { Route, Redirect } from 'react-router-dom'
const PrivateRoute = ({ LoadComponent, ...rest }) => {
    const token = localStorage.getItem('accessToken')
    return (
        <React.Fragment>
            <Route render={(props) => token && token.length > 0 ? (
                < LoadComponent {...props} />
            ) : <Redirect to={
                {
                    from: '*',
                    pathname: "/login",
                }
            }
            />} />
        </React.Fragment>
    )
}
export default PrivateRoute