import { Box, makeStyles,Container, useMediaQuery, useTheme, alpha, Chip, Card, Grid, Dialog, DialogTitle, DialogContent, FormControl, FormHelperText, DialogActions, Button, CircularProgress } from '@material-ui/core'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import Header from '../../components/Header'
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import { getClinicPackageTypes, getHospitalPackageList, getFilteredPackageListPagination, getMembersList, addHealthPackage, getHealthPackageTimeSlots,getFilteredProductListPagination, addProductToCartApi } from '../../request';
import { useLanguage } from '../../hooks/languageContext';
import ThemeLoader from '../../utils/Loader';
import { getToken, getUserData } from '../../utils/utils';
import '../../styles/style.css'
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import CallOptionComponent from '../../components/CallComponent';
import Text from '../../components/typography/TextTypography';
import { SearchProductComponent } from '../../components/Filter/SearchComponent';
import _ from 'lodash'
import SomethingWentWrong from '../error/SomethingWentWrong';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import viLocale from 'date-fns/locale/vi';
import DateFnsUtils from '@date-io/date-fns';
import ThemeSelectWithSelect from '../../components/formElements/ThemeSelectWithSelect';
import NewFooter from '../../components/NewFooter';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import cartIcon from '../../assets/icons/cartIcon.svg'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getProductDetails } from '../../request';
import { SomethingWentWrongProduct } from './ProductList';

import { AppBar, Divider, Tab, Tabs } from '@material-ui/core';
import parse from 'html-react-parser';
import AmountFormat from '../../components/AmountFormat';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
// import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';
// import uuid from 'uuid';
import { v4 as uuidv4 } from 'uuid';
import DialogContentText from '@mui/material/DialogContentText';
import { styled } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
const ProductDetail = () =>{
    const history = useHistory()
    const { lang, langContent,  cartCount,setCartCount } = useLanguage()
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const productId = query.get('productId')
    const [productDetail,setProductDetail] = useState([]);
    const [isLoading, setIsLoading]  = useState(true)
    const [responseError, setResponseError]  = useState(false)
    const theme = useTheme()
    const cookies = new Cookies(null, { path: '/' });
    const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));
    const token = getToken()
    const userId = getUserData('user_id')
    const [tabValue, setTabValue] = useState(0)
    const [ismodalOpen,setIsModalOpen] = useState(false)
    const [cartMessage,setCartMessage] = useState('')
    const [deleteCart,setDeleteCart] = useState( 'no')
    const [currentClickedProduct,setCurrentClickedProduct] = useState([])
    const formRef = useRef()
    const [requestPending, setRequestPending] = useState(false)
    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3} pl={0}>
                        <Text variant="textsm">{children}</Text>
                    </Box>
                )}
            </div>
        );
    }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    useEffect(()=>{
        console.log(productId);
    });

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const addProductToCart = (product) => {

        console.log(cookies.get('myCat')===undefined); // Pacman
            console.log(product);
            console.log("token",token);
            if(cookies.get('session_id')===undefined && token==false){
                const uniqueRandomString = uuidv4();
                cookies.set('session_id', uniqueRandomString);
                console.log(uniqueRandomString);
                console.log(cookies.get('session_id'));

            }else{
                console.log(cookies.get('session_id'));
            }
            const userSession = cookies.get('session_id')
            addProductToCartApi(lang,langContent, setIsLoading,productDetail,userSession,setCartCount,userId,deleteCart,setIsModalOpen,setRequestPending,setCartMessage,history)
            
            // localStorage.setItem('healthPackage', JSON.stringify(healthPackage))
            // setIsAddPackageDialogueOpen(true)
            // history.push(`/book_package/${hospital.dr_clinic_nice_name}?clinic=${healthPackage.dr_clinic_id}&package=${healthPackage.labtest_id}`)

        
    }
 
        useEffect(() => {
            getProductDetails(setProductDetail, lang, setIsLoading,productId,setResponseError)
        }, [ lang])

        const addNoImage = (ev) =>{
            ev.target.src ='https://fact.co.in/images/NoImage.png';
        }

        const handleClickOpen = () => {
            setIsModalOpen(true);
          };
        
          const handleClose = () => {
            setIsModalOpen(false);
          };
          const userCartConfirmation = () => {
            setIsModalOpen(false);
            setDeleteCart('yes')
            setTimeout(() => {
            }, 1000);
            // userCartConfirm(fd, history, token, langContent, setIsLoading, setRequestPending,data.member_id,setIsModalOpen,setCartMessage)
          };
          
          useEffect(()=>{
            if(deleteCart=='yes'){
                // formRef.current.requestSubmit()
                addProductToCart(currentClickedProduct)
            }
          },[deleteCart])
    return(
        <>
        <React.Fragment>
            <Header shouldHaveBackButton={true} />
            {
                isLoading ? <ThemeLoader /> :
                    responseError ?
                        <SomethingWentWrongProduct /> :
                        <Container style={{ marginBottom: '2rem' }}>
                            <Box mt={matchesSMDown ? 4 : 10} mb={matchesSMDown ? 4 : 9.2} textAlign='center'>
                                <Typography variant="h1">
                                    {productDetail.name}
                                </Typography>
                            </Box>
                            <Box border="2px solid rgba(4, 180, 227, 0.22)" p={matchesSMDown ? 2 : 2.25}>
                                <Grid container>
                                    <Grid item md={8}>
                                    <Box >
                                        <Box >
                                            <Box mt={2}>
                                                {
                                                    matchesSMDown ?
                                                        <Box textAlign='center' display="flex" justifyContent="Center" width="100%">
                                                            <Box border="1px solid #E5E5E5" style={{ padding: '10px 30px', borderRadius: '10px' }} mb={.8}  >
                                                                <img src={productDetail.featured_img ? productDetail.featured_img : ''} alt="Hospital Logo" height={100}  onError={ev => {addNoImage(ev)}} style={{ objectFit: 'contain', }} />
                                                            </Box>
                                                        </Box>
                                                        : null
                                                }
                                                <Text variant="textmd" color="#263238" fontWeight={600}>
                                                    {
                                                        productDetail
                                                            ?
                                                            lang === 'en' ? productDetail.name :
                                                                productDetail.name: null
                                                    }
                                                </Text>
                                            </Box>
                                            <Box mt={2}>
                                                <Text variant='body2'>
                                                    {productDetail.description ? parse(productDetail.description) : null}
                                                </Text>
                                            </Box>
                                        </Box>
                                    </Box>
                                    </Grid>
                                    <Grid item md={4}>
                                    <Box>
                                    
                                    <Box display={matchesSMDown ? 'none' : 'flex'} alignItems='center' justifyContent='flex-end'>
                                        <Box border="1px solid #E5E5E5" style={{ borderRadius: '10px',}}>
                                            <img src={productDetail.featured_img ? productDetail.featured_img : ''} onError={ev => {addNoImage(ev)}} alt="Hospital Logo" height={350} style={{ objectFit: 'contain'}} />
                                        </Box>
                                    </Box>
                                </Box>
                                    </Grid>
                                </Grid>
                              
                                <Box mt={3.5}>
                                    <Divider style={{ background: '#04B4E3', height: '.5px' }} />
                                </Box>
                                <Box mt={3.5} display="flex" flexDirection={matchesSMDown ? 'column' : 'row'} justifyContent='space-between'>
                                    <Box display="flex" alignItems='center'>
                                        <Text variant="textmd" fontWeight={600} color="#263238">
                                            {langContent.price}:
                                        </Text>
                                        <Box ml={1.2}>
                                            <Text variant="textmd" fontWeight={600} color="#263238">
                                                <AmountFormat value={productDetail.price} />
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box mt={matchesSMDown ? 2 : 0}>
                                        <Button variant="contained" fullWidth
                                            disabled={productDetail.buying_price === '0' || productDetail.buying_price === '' ? true : false}
                                            color="primary" size="small" style={{ color: 'white', fontSize: '0.9rem', marginBottom: '.5rem', padding: '8px 22px', }} onClick={() => addProductToCart()}>
                                            {langContent.add_to_cart}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={3.5} border="2px solid rgba(4, 180, 227, 0.22)"
                                p={matchesSMDown ? 2 : 7.25} pt={1}>
                                <AppBar position='static' color='transparent' elevation={0}>
                                    <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example"
                                        TabIndicatorProps={{
                                            color: 'primary'
                                        }}
                                    >
                                        <Tab label={langContent.about_test} style={{ minWidth: 100, fontSize: '18px', fontWeight: 600 }} {...a11yProps(0)} />
                                        <Tab label={langContent.pre_test_info} style={{ minWidth: 100, fontSize: '18px', fontWeight: 600 }} {...a11yProps(1)} />
                                        <Tab label={langContent.combo_products} style={{ minWidth: 100, fontSize: '18px', fontWeight: 600 }} {...a11yProps(2)} />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={tabValue} index={0}>
                                    <Text variant='textsm'>
                                        {productDetail.item_about ? parse(productDetail.item_about) : null}
                                    </Text>
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                </TabPanel>
                                <TabPanel value={tabValue} index={2}>
                                    <Box mt={2}>
                                        <Container>
                                            <Grid container spacing={4}>
                                                    {
                                                        productDetail.combo_products?.length>0 &&
                                                        productDetail.combo_products.map((product,index)=>(
                                                            <Grid item md={4}>
                                                                <RecipeReviewCard {...{
                                                                        product
                                                                    }} key={index} />
                                                            </Grid>
                                                        ))

                                                    }
                                            </Grid>
                                        </Container>
                                    </Box>
                                </TabPanel>
                            </Box>
                        </Container>
            }
            <Dialog open={ismodalOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                {"Cart Message"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {cartMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={userCartConfirmation} autoFocus>
                    Yes
                </Button>
                </DialogActions>
            </Dialog>
            <NewFooter />
        </React.Fragment>
        </>
    )
}

export default ProductDetail;

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  
  export  function RecipeReviewCard({product}) {
    useEffect(()=>{
        console.log(product);
        
    },[product])
    const addNoImage = (ev) =>{
        ev.target.src ='https://fact.co.in/images/NoImage.png';
    }

    const [expanded, setExpanded] = React.useState(false);
  
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
  
    return (
      <Card sx={{ maxWidth: 345 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              <CardGiftcardIcon/>
            </Avatar>
          }
         
          title={product.name}
          titleTypographyProps={{ fontWeight: '600' }}
          subheader=""
        />
        <CardMedia
          component="img"
          height="194"
          image={product.featured_img}
          alt="Product Image"
          onError={ev => {addNoImage(ev)}}
        />
        <CardContent>
        <Text variant="textmd" fontWeight={600} color="error">
            <AmountFormat value={product.price} />
        </Text>
          <Typography variant="body2" color="text.secondary">
            {
                product.description
            }
          </Typography>
        </CardContent>
      </Card>
    );
  }