/* eslint-disable eqeqeq */
import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';
import React from 'react';
import { useLanguage } from '../../hooks/languageContext';
const DOBSelect = ({ name, label, errors, isRequired, value = '', variant = "outlined", valueKey = '', labelKey = '', register, optionObj = [], selectLabel = 'select', ...rest }) => {
    const { langContent } = useLanguage()
    return (
        <>
            <FormControl variant={variant}  {...rest}>
                <InputLabel htmlFor="outlined-age-native-simple" required={isRequired} shrink={true}>{label}</InputLabel>
                <Select
                    required={isRequired}
                    native
                    notched
                    error={Boolean(errors[name])}
                    name={name}
                    label={label}
                    inputProps={{
                        id: 'outlined-age-native-simple',
                        inputRef: register
                    }}
                    value={value}
                >
                    <option value="" key={null}>
                        {selectLabel}
                    </option>
                    {
                        optionObj.length && optionObj.map((obj, key) => (<option value={valueKey ? obj[valueKey] : obj.key}  key={key}>{labelKey ? obj[labelKey] : obj.label}</option>))
                    }
                </Select>
            </FormControl>
            {errors[name] ? <div style={{ display: 'flex', justify: 'flex-start', color: 'tomato', marginTop: '.5rem', textTransform: 'capitalize' }}>
                <FormHelperText style={{ color: 'tomato' }}>{errors[name]?.message}</FormHelperText>
            </div> : ''}
        </>
    )
}
export default DOBSelect