import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { alpha, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import iqiLogo from "../assets/img/logo.png";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  useMediaQuery,
  Button,
  Menu,
  FormControl,
  Select,
  Badge,
  Typography,
  ListItem,
} from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { useTheme } from "@material-ui/styles";
import { useHistory } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  appVersion,
  loggedInNavigation,
  UnAuthenticatedNavigationList,
} from "../static";
import { useLanguage } from "../hooks/languageContext";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getToken, logout, getUserData } from "../utils/utils";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { getCartCount, getCorporatePackageList,deleteUserAccount,getUserNotificationCount } from "../request";
import { useForm } from "react-hook-form";
import "../styles/style.css";
import HeaderNavTabs, { HeaderNavTabsUnAuthorized } from "./HeaderNavTabs";
import Text from "./typography/TextTypography";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import UserIdleTimer from "./UserIdleTime";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid grey",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  fullWidthSearch: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid grey",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
      display: "none",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "40ch",
      },
    },
    [theme.breakpoints.up("md")]: {
      width: "30ch",
      "&:focus": {
        width: "50ch",
      },
    },
  },
  fullWidthInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "34ch",
    },
  },
  divider: {
    background: "red",
    position: "relative",
    width: "2px",
    marginRight: ".5rem",
    marginLeft: ".4rem",
  },
  menu: {
    width: 200,
  },
  toolbar: theme.mixins.toolbar,
}));
/**
 * ! Returns Header
 * @returns Header
 */
export default function Header({
  shouldHaveBackButton,
  shouldHaveSearchBar,
  showBottomToolbar = true,
}) {

  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(null);
  const theme = useTheme();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true)
  const matchesSMAbove = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMDAbove = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSMOnly = useMediaQuery(theme.breakpoints.only("sm"));
  const matchesMDDown  = useMediaQuery(theme.breakpoints.down("md"));
  const {
    lang,
    setLang,
    langContent,
    activeCity,
    setActiveCity,
    setLat,
    setLng,
    searchValue,
    setSearchValue,
    setSearchType,
    cartCount,
    setCartCount,
  } = useLanguage();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cityName, setCityName] = useState(null);
  const activeCityList =
    JSON.parse(localStorage.getItem("activeCityList")) || [];
  const token = getToken();
  const userId = getUserData("user_id");
  const [locationAnchorEl, setLocationAnchorEl] = useState(null);
  const [isNotificationInvisible, setIsNotificationInvisible] = useState(true);
  const [showNotificationCount, setShowNotificationCount] = useState(true);
  const alertCorporate = localStorage.getItem("alertCorporate") || false;
  const corporateHospital_id = getUserData("hospital_id");
  const isCorporateUser = corporateHospital_id ? true : false;
  const [notificationCount, setNotificationCount] = useState(0)
  useEffect(()=>{
    if(notificationCount>0){
      setShowNotificationCount(false)
    }
  },[notificationCount])

  let setNavigatorStatus = null;
  const handleLocationClick = (e) => {
    setLocationAnchorEl(e.currentTarget);
  };
  const handleLocationClose = (e) => {
    setLocationAnchorEl(null);
  };
  const onLocationChange = (e) => {
    setLocationAnchorEl(null);
    const { location } = e.target.dataset;
    onCityChange(location);
  };
  const handleNotificationClick = (e) => {
    // setShowNotificationDropdown(e.currentTarget);
    history.push('/notifications')
  };
  const onNotificationChange = (e) => {
    if (isCorporateUser && !alertCorporate) {
      localStorage.setItem("alertCorporate", true);
    }
    const { pagelink } = e.target.dataset;
    history.push(pagelink);
  };
  const onNotificationClose = (e) => {
  };

  /**
   * ! Language DropDown Functions
   * @languageDropdownClick :::=> ! This will open the drop down link for language change
   * @languageDropdownClose :::=> ! This will change the language as per user's choice
   */
  const languageDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const languageDropdownClose = (e) => {
    setAnchorEl(null);
    const { language } = e.currentTarget.dataset;
    if (language === undefined) {
      return;
    }
    setLang(language);
    localStorage.setItem("lang", language);
  };
  const { register, handleSubmit } = useForm();
  const onSearch = (data) => {
    history.push({
      pathname: "/search",
      search: `?query=${data.search_value}`,
    });
  };
  useEffect(() => {
    let cityName = "";
    if (activeCity == -2) {
      setCityName(langContent.near_here);
    } else {
      activeCityList.forEach((city) => {
        if (city.city_id === activeCity) {
          cityName = lang === "en" ? city.city_name_en : city.city_name_vn;
          setCityName(cityName);
        }
      });
    }
    return () => {};
  }, [activeCityList]);
  /**
   * ! This gets called whenever user changes the city from the dropdown
   */
  const onCityChange = (val) => {
    setActiveCity(val);
    if (val === "-2") {
      getLocation();
      setSearchType("geolocation");
    } else {
      localStorage.setItem("setActiveCity", val);
      localStorage.setItem("searchType", "city");
      setSearchType("city");
    }
  };
  /**
   * ! These are Responsible for getting user's Latitude and Longitude and store them in local Storage
   * ! Also , It prompts user for location permission and if allowed it store them in local Storage
   * ! And changes the Search Type to geolocation, ie, search Result will now be base on the geolocation
   * @param {*} pos
   */
  function success(pos) {
    var crd = pos.coords;
    localStorage.setItem("lat", crd.latitude);
    localStorage.setItem("lng", crd.longitude);
    setLat(crd.latitude);
    setLng(crd.longitude);
    setActiveCity("-2");
    localStorage.setItem("setActiveCity", "-2");
    localStorage.setItem("searchType", "geolocation");
  }
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function permissionErrors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  useEffect(() => {
    /**
     * ! If User is Logged In fetch The cart count
     */
    // checkInternet(langContent)
    if (token) {
      getCartCount(token, setCartCount, setIsNotificationInvisible);
      getUserNotificationCount(lang,userId,setIsLoading,setNotificationCount)
    }else{
      getCartCount('', setCartCount, setIsNotificationInvisible);
    }

    return () => {};
  }, []);
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(success);
            //If granted then you can directly call your function here
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              success,
              permissionErrors,
              options
            );
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {};
        });
    }
    if (!navigator.geolocation) {
      setNavigatorStatus = "Geolocation is not supported by your browser";
    } else {
      setNavigatorStatus = "Locating...";
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setNavigatorStatus = null;
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        () => {
          setNavigatorStatus = "Unable to retrieve your location";
        }
      );
    }
  };

  const deleteAccount = () =>{
    // if(window.confirm("Are you sure, you want to deactivate your account?")){
    //   deleteUserAccount(userId,token,lang);
    // }
    history.push('/deactivate_account')
  }
  /**
   * ! This contains the Drawer UI Component
   */
  const DrawerItem = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <List style={{ width: "100%" }}>
          {token ? (
            <>
              {loggedInNavigation.map((item, index) => (
                <div key={index}>
                  <MenuItem
                    button
                    onClick={() => {
                      setIsSelected(index);
                      history.push(item.route);
                    }}
                  >
                    <ListItemIcon>
                      {index === 11 ? (
                        <item.Icon color="error" />
                      ) : (
                        <item.Icon color="primary" />
                      )}
                    </ListItemIcon>
                    <ListItemText>{langContent[item.linkName]}</ListItemText>
                  </MenuItem>
                  <Divider />
                </div>
              ))}
                <MenuItem
                    button
                    onClick={deleteAccount}
                  >
                <ListItemIcon color="error">
                  <PowerSettingsNewIcon color="error"/>
                </ListItemIcon>
                <ListItemText style={{color:'#d32f2f'}}>{langContent.delete_account}</ListItemText>
              </MenuItem>
            </>
          ) : (
            UnAuthenticatedNavigationList.map((item, index) => (
              <div key={index}>
                <MenuItem
                  button
                  onClick={() => {
                    
                      setIsSelected(index);
                      history.push(item.route);
                    
                  }}
                  selected={isSelected === index}
                >
                  <ListItemIcon>
                    {index === 4 ? (
                      <FontAwesomeIcon
                        icon={faHandshake}
                        style={{ color: "#28368C" }}
                      />
                    ) : (
                      <item.Icon style={{ color: "#28368C" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText>{langContent[item.linkName]}</ListItemText>
                </MenuItem>
                <Divider />
              </div>
            ))
          )}
        </List>
        <Box textAlign="center">
          <Typography variant="h6" style={{ fontWeight: "600" }}>
            V : {appVersion}
          </Typography>
        </Box>
      </div>
    );
  };

  const location = useLocation();
  useEffect(()=>{
    setSearchValue('')
  },[location]) 
  /**
   * ! This Returns the actual Header Containing the Drawer as well
   */
  return (
    <div className={classes.root}>
        {/* <UserIdleTimer history={history}/> */}
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "white",
          paddingTop: ".8rem",
          paddingLeft: matchesSMDown ? 0 : ".6rem",
          paddingRight: ".6rem",
        }}
        elevation={1}
      >
        <Toolbar style={{ minHeight: matchesMDAbove ? null : 22 }}>
          {(matchesSMDown  || matchesMDDown)  ? (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => setIsDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <Box
            display="flex"
            style={{ alignSelf: "flex-start", textAlign: "center" }}
            mt={matchesSMAbove ? 2.5 : 1.4}
            ml={-2}
            alignItems="center"
          >
            {
              /**
               * ! Show Location Icon On devices larger than small devices
               */
              matchesSMAbove ? (
                <Box display="flex" mt={matchesSMOnly ? -1 : 0}>
                  <LocationOnIcon
                    color="primary"
                    style={{ marginRight: ".3rem", cursor: "pointer" }}
                    onClick={handleLocationClick}
                  />
                  <Box borderBottom="1px solid #02abd6">
                    <Text variant="textsm">
                      {
                        // activeCityList[activeCity].city_name_en
                        cityName
                      }
                    </Text>
                  </Box>
                </Box>
              ) : (
                <LocationOnIcon
                  color="primary"
                  style={{ marginRight: ".3rem", cursor: "pointer" }}
                  onClick={handleLocationClick}
                />
              )
            }
            <Menu
              anchorEl={locationAnchorEl}
              keepMounted
              open={Boolean(locationAnchorEl)}
              onClose={handleLocationClose}
              value={activeCity}
              // onChange={onCityChange}
            >
              <MenuItem
                data-location={-2}
                onClick={(e) => onLocationChange(e)}
                style={{
                  backgroundColor: activeCity == -2 ? "#02abd6" : "white",
                  color: activeCity == -2 ? "white" : "black",
                }}
              >
                {" "}
                {langContent.near_here}{" "}
              </MenuItem>
              {activeCityList.map((city, index) => (
                <MenuItem
                  data-location={city.city_id}
                  onClick={(e) => onLocationChange(e)}
                  style={{
                    backgroundColor:
                      city.city_id === activeCity ? "#02abd6" : "white",
                    color: city.city_id === activeCity ? "white" : "black",
                  }}
                  key={index}
                >
                  {lang === "en" ? city.city_name_en : city.city_name_vn}
                </MenuItem>
              ))}
            </Menu>
            
          </Box>
         
          <Box
            className={classes.title}
            flexGrow={2}
            display="flex"
            justifyContent="center"
          >
            <img
              src={iqiLogo}
              alt="Logo"
              style={{
                cursor: "pointer",
                marginLeft:
                  matchesMDAbove && !token && !shouldHaveSearchBar
                    ? "-4.5rem"
                    : matchesMDAbove && token && shouldHaveSearchBar
                    ? "1rem"
                    : matchesMDAbove && token && !shouldHaveSearchBar
                    ? "-2.5rem"
                    : matchesMDAbove && !token && shouldHaveSearchBar
                    ? "2rem"
                    : "",
              }}
              height={matchesMDAbove ? 50 : 50}
              width={matchesMDAbove ? 150 : 150}
              onClick={() => history.push("/")}
              loading="lazy"
            />
             { (matchesMDAbove && !matchesMDDown) ? (
            <Toolbar style={{ display: "flex", justifyContent: "center" }}>
              <Box display="flex" style={{ flexWrap: "wrap" }}>
                {token ? <HeaderNavTabs /> : <HeaderNavTabsUnAuthorized />}
              </Box>
            </Toolbar>
         
      
      ) : null}
          </Box>
          <Box>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={languageDropdownClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {lang === "vn" ? "Vn" : "En"}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={languageDropdownClose}
              style={{ minWidth: 200 }}
            >
              <MenuItem data-language="en" onClick={languageDropdownClose}>
                English
              </MenuItem>
              <MenuItem data-language="vn" onClick={languageDropdownClose}>
                Tiếng Việt
              </MenuItem>
            </Menu>
          </Box>
          {/**
           * ! This Search Bar is Visible on View larger than small devices
           */}
          <Box
            className={classes.search}
            style={{
              display: shouldHaveSearchBar && matchesMDAbove ? "block" : "none",
            }}
          >
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <form onSubmit={handleSubmit(onSearch)}>
              <InputBase
                placeholder={langContent.search_place_holder}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                inputProps={{ "aria-label": "search" }}
                inputRef={register}
                name="search_value"
              />
            </form>
          </Box>
          {/* 
                    
                    Notification Drop Down Menu for logged in users
                    
                */}
                {
                  <Box style={{marginLeft:'1rem'}}>
                   {cartCount <= 0 ? (
                  <ShoppingCartIcon
                    style={{ fontSize: "1.4rem", cursor: "pointer" }}
                    onClick={() => history.push("/cart")}
                  />
                ) : (
                  <Badge badgeContent={cartCount} color="primary">
                    <ShoppingCartIcon
                      style={{ fontSize: "1.4rem", cursor: "pointer" }}
                      onClick={() => history.push("/cart")}
                    />
                  </Badge>
                )}
                  </Box>
               
              }
          {token ? (
            <Box ml={1} display="flex" alignItems="center">
              {/* {cartCount <= 0 ? (
                <ShoppingCartIcon
                  style={{ fontSize: "1.4rem", cursor: "pointer" }}
                  onClick={() => history.push("/cart")}
                />
              ) : (
                <Badge badgeContent={cartCount} color="primary">
                  <ShoppingCartIcon
                    style={{ fontSize: "1.4rem", cursor: "pointer" }}
                    onClick={() => history.push("/cart")}
                  />
                </Badge>
              )} */}
              <Box ml={1.2} style={{marginTop: matchesSMDown ? '-0.6rem' :'-0.5rem'}}>
                <Badge
                  color="secondary"
                  // variant="dot"
                  onClick={handleNotificationClick}
                  invisible={showNotificationCount}
                  aria-controls="notification"
                  aria-haspopup="true"
                  style={{ cursor: "pointer" }}
                  badgeContent={notificationCount}
                >
                  <NotificationsNoneIcon
                    color={
                      isCorporateUser && !alertCorporate
                        ? "secondary"
                        : "primary"
                    }
                    fontSize="medium"
                  />
                </Badge>
                
              </Box>
              {matchesMDAbove ? (
                <Button
                  onClick={() => logout()}
                  className="textHover"
                  style={{
                    textDecoration: "none",
                    color: "#02abd6",
                    marginLeft: "8px",
                  }}
                >
                  {langContent.logout}
                </Button>
              ) : null}
            </Box>
          ) : null}
          <Box>
            {/* 
                        <Badge color="secondary" variant="dot" invisible={false} fontSize={10} onClick={() => setShowNotificationDropdown(true)} aria-controls="notification" aria-haspopup="true">
                            <NotificationsNoneIcon color='primary' fontSize='small' />
                        </Badge>
                        <Menu
                            id="notification"
                            anchorEl={showNotificationDropdown}
                            keepMounted
                            open={showNotificationDropdown}
                            onClose={() => setShowNotificationDropdown(false)}
                        >
                            <MenuItem >Profile</MenuItem>
                            <MenuItem >My account</MenuItem>
                            <MenuItem >dfd</MenuItem>
                        </Menu> */}
          </Box>

          <SwipeableDrawer
            anchor="left"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            onOpen={() => setIsDrawerOpen(true)}
          >
            <DrawerItem />
          </SwipeableDrawer>
        </Toolbar>
        {/**
         *  ! This Search Bar is visible on Mobile device and it replaces the search Bar in desktop view
         */}
        <Toolbar
          variant="dense"
          style={{
            display: matchesMDAbove
              ? "none"
              : shouldHaveSearchBar
              ? "block"
              : "none",
          }}
        >
          <div className={classes.fullWidthSearch}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <form onSubmit={handleSubmit(onSearch)}>
              <InputBase
                placeholder={langContent.search_place_holder}
                classes={{
                  root: classes.inputRoot,
                  input: classes.fullWidthInput,
                }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                inputProps={{ "aria-label": "search" }}
                inputRef={register}
                name="search_value"
              />
            </form>
          </div>
        </Toolbar>
        <Toolbar
          variant="dense"
          style={{
            display: !matchesMDAbove && shouldHaveBackButton ? "block" : "none",
            minHeight: 8,
          }}
        >
          <ArrowBackIcon
            style={{ color: "#81d5eb", cursor: "pointer" }}
            onClick={() => history.goBack()}
          />
           
        </Toolbar>
      </AppBar>
      <Box
        className={classes.toolbar}
        style={{
          display: showBottomToolbar ? "block" : "none",
          height: !matchesMDAbove
            ? shouldHaveBackButton && !shouldHaveSearchBar
              ? "3rem"
              : shouldHaveBackButton && shouldHaveSearchBar
              ? "8rem"
              : "3rem"
            : "3.9rem",
        }}
      ></Box>
      
    </div>
  );
}
