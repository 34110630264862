import React from 'react'
import { Box, alpha, InputBase, FormControl, TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useLanguage } from '../../hooks/languageContext';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        border: '1px solid grey',
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    /** 
     * ! This sets the inner width of the input search field
     */
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    fullWidthInput: {
        padding: theme.spacing(1, 2, 1, 1),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
}))
export const SearchComponent = ({ register, isLoading, submitHandler, onSearchSubmit }) => {
    const classes = useStyles();
    const { langContent } = useLanguage()
    return (
        <form onSubmit={submitHandler(onSearchSubmit)}>
            <FormControl variant='outlined' fullWidth>

                <TextField
                    label=""
                    name="search_field"
                    variant="outlined"
                    size="small"
                    placeholder={langContent.search_package}
                    disabled={isLoading}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        ),


                    }}
                    inputRef={register}
                />
            </FormControl>
        </form>
    )
}
export const SearchProductComponent = ({ register, isLoading, submitHandler, onSearchSubmit }) => {
    const classes = useStyles();
    const { langContent } = useLanguage()
    return (
        <form onSubmit={submitHandler(onSearchSubmit)}>
            <FormControl variant='outlined' fullWidth>

                <TextField
                    label=""
                    name="search_field"
                    variant="outlined"
                    size="small"
                    placeholder={langContent.search_product}
                    disabled={isLoading}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        ),


                    }}
                    inputRef={register}
                />
            </FormControl>
        </form>
    )
}