import { FormHelperText, InputAdornment, TextField,useTheme } from '@material-ui/core';
import React from 'react'
import { alpha, makeStyles } from "@material-ui/core/styles"
const InputField = ({ register, errors }) => {
 

    return (
        <>
            <TextField style={{ width: '3rem', borderRight: '1 px solid red' }} autoComplete="new-password" InputProps={{
                autoComplete: 'none',
                maxLength: 3,
                disableUnderline: true, underline: {
                    "&&&:before": {
                        borderBottom: "none"
                    },
                    "&&:after": {
                        borderBottom: "none"
                    },
                    '&&:focus': {
                        border: 'none'
                    }
                },
            }}
                inputProps={{ maxLength: 3 }}
                name='country_code'
                value="+84"
                inputRef={register}
                disabled
                
            />
            <div style={{ height: '2.5rem', width: '1px', marginRight: '10px', backgroundColor: '#e1e1e1' }} />
        </>
    )
}
const ThemeInputMobile = ({
    name, register, errors, isRequired, label,maxLength,minLength, ...rest
}) => {
    return (
        <>
            <TextField
                error={Boolean(errors[name])}
                label={label}
                required={isRequired}
                name={name}
                inputRef={register}
                variant="outlined"
                type="number"
                minLength='9'
                maxLength='9'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <InputField register={register} errors={errors} />
                        </InputAdornment>
                    ),
                }}
                {...rest}
            />
            {errors[name] ? <div style={{ display: 'flex', justifyContent: 'flex-start', color: 'tomato', marginTop: '.5rem', textTransform: 'capitalize' }}>
                {/* <small>{errors[name]?.message}</small> */}
                <FormHelperText style={{ color: 'tomato' }}>{errors[name]?.message}</FormHelperText>
            </div> : ''}
        </>
    )
}
export default ThemeInputMobile;