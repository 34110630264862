import moment from 'moment';
import 'moment/locale/vi';
import 'moment/locale/en-au'
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';

export const pxTorem = (val) => {
    return (1 / 16) * val + "rem";
};
export const dateFormat = (lang, date) => {
    return lang === 'vn' ? moment(date).locale('vi').format("D MMMM YYYY") : moment(date).locale('en-au').format("DD MMMM YYYY")
}
export const dateFormatWithTime = (lang, date) => {
    return lang === 'vn' ? moment(date).locale('vi').format("D MMMM YYYY, HH:mm") : moment(date).locale('en-au').format("DD MMMM YYYY, HH:mm")
}
export const genderEn = (key) => {
    const myArray = { 'M': 'Male', 'F': 'Female', 'T': 'Others' };
    return myArray[key];
}
export const genderVn = (key) => {
    const myArray = { 'M': 'Nam', 'F': 'Nữ', 'T': 'Khác' };
    return myArray[key];
}
export const marriedVn = (key) => {
    const myArray = { 'Married': 'Đã kết hôn', 'Single': 'Độc thân', 'Divorced': 'Đã ly hôn' };
    return myArray[key];
}
export const ageCalculator = (dob, lang) => {
    return lang === 'en' ? moment(dob, "YYYYMMDD").fromNow(true) : moment(dob, "YYYYMMDD").locale('vi').fromNow(true)
}
export const checkLoginStatus = () => {
    const status = localStorage.getItem('loginStatus') ? localStorage.getItem('loginStatus') : false;
    return status
}
export const getToken = () => {
    const token = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : false
    return token
}
export const getUserData = (param) => {
    const userData = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : false
    return userData[param]
}
export const SHOW_TOAST_MESSAGE = (message) => {
    const notify = () => toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    return notify();
}
export const SHOW_TOAST_MESSAGE_SUCCESS = (message) => {
    const notify = () => toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    return notify();
}
export const getSelectedHospitalData = (param) => {
    if (!(localStorage.getItem('selected_hospital'))) {
        return false;
    } else {
        const userData = JSON.parse(localStorage.getItem('selected_hospital'))
        return userData[param]
    }
}
export const getDoctorData = (param) => {
    if (!localStorage.getItem('selectedDoctor')) {
        return false
    } else {
        const doctorData = JSON.parse(localStorage.getItem('selectedDoctor'))
        return doctorData[param]
    }
}
/**
 * 
 * ! This function will return the app key for access of any API
 */
export const getAppKey = () => {
    return process.env.REACT_APP_APP_SECRET_KEY
}
export const logout = () => {
    const cookies = new Cookies(null, { path: '/' });
    const searchType = localStorage.getItem('searchType')
    const activeCityList = localStorage.getItem('activeCityList')
    const lang = localStorage.getItem('lang')
    localStorage.clear()
    localStorage.setItem('activeCityList', activeCityList)
    localStorage.setItem('searchType', searchType)
    localStorage.setItem('loginStatus', false)
    localStorage.setItem('lang', lang)
    cookies.remove('session_id');
    window.location = '#/login'
}

export const isEmpty = (arg) => {
    if (arg === '' || arg === undefined || arg === null || arg === 'null') {
        return true
    }
    return false
}