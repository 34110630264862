import axios from 'axios'
import { getToken, getUserData } from './utils';
import { useHistory } from 'react-router'

const axiosInstance = axios.create({
  // baseURL: 'https://www.iqihealth.net/admin'
    //  baseURL: "https://viccare.victoriavn.com/admin",
  // baseURL: "http://victoria-/iqi.loc/admin",
  baseURL: "https://ecommerce.victoriavn.com/admin",
  // baseURL: "http://localhost/victoria-iqihealth.net/html/admin",
});
/**
 * ! Here, we intercept request before sending and thus we can change or append any header variable
 * accept-encoder will store the app ke(static) in environment variable
 */



export default axiosInstance