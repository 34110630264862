/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React from 'react'
import { useState, useEffect } from 'react';
import { getSurveyQuestions } from '../request';
import ThemeLoader from '../utils/Loader';
import { Box,CircularProgress, Typography,MenuItem,Menu, useMediaQuery, Divider, Container, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Grid, TextareaAutosize } from '@material-ui/core';
import parse from 'html-react-parser';
import '../styles/style.css'
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import NewFooter from './NewFooter';
import NewHeader from './NewHeader';
import surveyBg from './../assets/img/survey_bg.jpg'
import { useLanguage } from '../hooks/languageContext';
import { Label } from '@material-ui/icons';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { saveHomeCareSurveyForm } from "./../request";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import Text from "./../components/typography/TextTypography";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { homeCareSurveyFormValidation } from "./../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getUserData, getToken } from "./../utils/utils";
import iqiLogo from "../assets/img/logo.png";
import { isEmpty } from 'lodash';
const useStyles = makeStyles((theme) => ({
    divider: {
        background: 'red',
        width: '2px',
        marginLeft: '10px',
        marginRight: '10px',

    }
}))
const UserHomeCareSurvey = () => {
    const { lang, langContent,setLang, } = useLanguage();
    const [isLoading, setIsLoading] = useState(false)
    const [about, setAbout] = useState(null)
    const [requestPending, setRequestPending] = useState(false);
    const [contactUs, setContactUs] = useState(null)
    const [aboutUsTitle, setAboutUsTitle] = useState(null)
    const [contactUsTitle, setContactUsTitle] = useState(null)
    const [surveyQuestions, setSurveyQuestions] = useState(null)
    const theme = useTheme()
    const classes = useStyles();
    const token = getToken();
    const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const location = useLocation()
    const urlPath = (location.pathname)
    const splitPath = urlPath.split('/')
    const userID = splitPath[2]
    const [anchorEl, setAnchorEl] = React.useState(null);
 
    const handleClickOpen = () => {
        setOpen(true);
    };
    const validateValues = (formObject) => {
        console.log(); // true
        console.log(formObject);
        let errors = {};
        if (formObject.hasOwnProperty("question1") == false) {
          errors.question1 = "This is required field";
        }
        if (formObject.hasOwnProperty("question2") == false) {
          errors.question2 = "This is required field";
        }
        if (formObject.hasOwnProperty("question3") == false) {
          errors.question3 = "This is required field";
        }
        if (formObject.hasOwnProperty("question4") == false) {
          errors.question4 = "This is required field";
        }
        if (formObject.hasOwnProperty("question5") == false) {
          errors.question5 = "This is required field";
        }
        if (formObject.hasOwnProperty("question6") == false) {
          errors.question6 = "This is required field";
        }
        if (formObject.hasOwnProperty("question7") == false) {
          errors.question7 = "This is required field";
        }
        if (formObject.hasOwnProperty("question8") == false) {
          errors.question8 = "This is required field";
        }
        console.log(errors);
        return isEmpty(errors)
      };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {

        getSurveyQuestions(lang,userID, setSurveyQuestions,setIsLoading,history);
    }, [lang])
    useEffect(() => {
        console.log(surveyQuestions);
    }, [surveyQuestions]);
    const languageDropdownClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const languageDropdownClose = (e) => {
      setAnchorEl(null);
      const { language } = e.currentTarget.dataset;
      if (language === undefined) {
        return;
      }
      setLang(language);
      localStorage.setItem("lang", language);
    };
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        // textAlign: 'center',
        paddingLeft:'1rem',
        paddingTop:'1rem',
        color: theme.palette.text.secondary,
      }));
      const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(homeCareSurveyFormValidation),
      });
      const onSubmit = (event) => {
        event.preventDefault();
        var data = new FormData(event.target);
        console.log("data",data);
        data.append('userID',userID)
        let formObject = Object.fromEntries(data.entries());
     
        console.log(Object.keys(formObject).length);
        console.log(formObject);
        console.log(userID);
        if(validateValues(formObject) ==true){
          // console.log("form submitted");
           saveHomeCareSurveyForm(
            formObject,
            token,
            setIsLoading,
            langContent,
            history,
            setRequestPending
          );
        }else{
          alert("please give feedback for all questions")
        }
        // return false;
       
      };
      const handleChange = (e) => {
        setInputFields({ ...inputFields, [e.target.name]: e.target.value });
      };
      useEffect(()=>{
        console.log(errors);
      },[errors])
    return (
        <React.Fragment>
            {
                isLoading ? <ThemeLoader />
                    :
                  <Container>
                    <Box mt={4} mb={4}>
                        <Grid container> 
                            <Grid item md={11} sm={11}  >
                              <Grid container>
                                <Grid item md={12} sm={12} style={{display:'flex',justifyContent:'center'}}>
                                  <img src={iqiLogo} alt="" style={{width:'250px'}} />
                                </Grid>
                                <Grid item md={12} sm={12} style={{display:'flex',justifyContent:'center'}}>
                                  <Typography variant='h4' fontWeight={600}>
                                      Home Care Service Survey Form
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={1} sm={1}>
                              <Box>
                                <Button
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={languageDropdownClick}
                                  endIcon={<KeyboardArrowDownIcon />}
                                >
                                  {lang === "vn" ? "Vn" : "En"}
                                </Button>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={languageDropdownClose}
                                  style={{ minWidth: 200 }}
                                >
                                  <MenuItem data-language="en" onClick={languageDropdownClose}>
                                    English
                                  </MenuItem>
                                  <MenuItem data-language="vn" onClick={languageDropdownClose}>
                                    Tiếng Việt
                                  </MenuItem>
                                </Menu>
                              </Box>
                            </Grid>
                        </Grid>
                    </Box>
                   
                    <Box>
                    <form onSubmit={onSubmit} noValidate method='post'>
                    {
                        surveyQuestions && surveyQuestions.map((item, index) => (
                            <Item style={{marginBottom:'1rem'}} key={index}>
                                <Box mb={3}>
                                    <Grid container spacing={matchesSMDown ? 3 : 1}>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <FormControl>
                                                <FormLabel id="demo-row-radio-buttons-group-label"> {index + 1}. {lang === 'en' ? item.question_en : item.question_vn}</FormLabel>
                                                {item?.question_type==='radio' ? 
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name={'question'+item?.id}
                                                    id={'question'+item?.id}
                                                    register={register}
                                                    errors={errors}
                                                >
                                                    <FormControlLabel value="yes" control={<Radio inputRef={register} />} label="Yes" />
                                                    <FormControlLabel value="no" control={<Radio inputRef={register} />} label="No" />
                                                </RadioGroup>
                                                :
                                               ""
                                            }
                                            {
                                                item?.question_type=='textarea' ? 
                                                <MinHeightTextarea name={'question'+item?.id} errors={errors}/>
                                                :""
                                            }
                                            {errors.question1 ? (
                                              <p className="error">
                                                Email should be at least 15 characters long
                                              </p>
                                            ) : null}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Item>
                        ))
                    }
                    <Grid item md={12} sm={12} xs={12}>
                        <Box
                        display="flex"
                        justifyContent="center"
                        mt={7.8}
                        mb={2.6}
                        >
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={requestPending ? true : false}
                            color="primary"
                            style={{ padding: "8px 22px", color: "white" }}
                        >
                            {requestPending && (
                            <>
                                <CircularProgress color="primary" size={22} />
                                <Text
                                variant="textxs"
                                color="#02abd6"
                                style={{ marginLeft: "10px" }}
                                >
                                {langContent.loading}..
                                </Text>
                            </>
                            )}
                            {!requestPending && langContent.submit_form}
                        </Button>
                        </Box>
                    </Grid>
                    </form>
                    </Box>
                  </Container>
            }
            <Box>
            </Box>
            {!isLoading && <NewFooter />}

        </React.Fragment>
    )
}
export default UserHomeCareSurvey

function MinHeightTextarea(obj) {
    const blue = {
      100: '#DAECFF',
      200: '#b6daff',
      400: '#3399FF',
      500: '#007FFF',
      600: '#0072E5',
      900: '#003A75',
    };
  
    const grey = {
      50: '#F3F6F9',
      100: '#E5EAF2',
      200: '#DAE2ED',
      300: '#C7D0DD',
      400: '#B0B8C4',
      500: '#9DA8B7',
      600: '#6B7A90',
      700: '#434D5B',
      800: '#303740',
      900: '#1C2025',
    };
  
    const Textarea = styled(BaseTextareaAutosize)(
      ({ theme }) => `
      box-sizing: border-box;
      width: 50vw;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 8px 12px;
      border-radius: 8px;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
      &:hover {
        border-color: ${blue[400]};
      }
  
      &:focus {
        border-color: ${blue[400]};
        box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      }
  
      // firefox
      &:focus-visible {
        outline: 0;
      }
    `,
    );
  
    return (
      <Textarea aria-label="minimum height" minRows={2} placeholder="" name={obj.name} id={obj.name} errors={obj.errors} />
    );
  }