import React, { useEffect } from "react";

import moment from 'moment';
import { useState } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useLanguage } from '../hooks/languageContext';
import { getUserData } from '../utils/utils';
import { checkAccountStatus } from '../request';
import { getToken, logout } from "../utils/utils";
function UserIdleTimer({history}) {

  const [state, setState] = useState('Active')
  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(0)
  const [isLoggedin,setIsLoggedIn] = useState(false)
  const userId = getUserData('user_id')
  const { lang, langContent } = useLanguage()
  const token = getToken();
  const onIdle = () => {
    setState('Idle')
    logout();
  }

  const logout = () =>{
    const searchType = localStorage.getItem('searchType')
    const activeCityList = localStorage.getItem('activeCityList')
    localStorage.clear()
    localStorage.setItem('loginStatus', false)
    localStorage.setItem('activeCityList', activeCityList)
    localStorage.setItem('searchType', searchType)
    history?.replace('/')
  }

  const onActive = () => {
    setState('Active')
  
  }

  const onAction = () => {
     if(isLoggedin==true)
    setCount(count + 1)
  }

  useEffect(()=>{
    let lastActivityTime = localStorage?.getItem('lastActivityTime');
    let currentDateTime = moment().format('YYYY-MM-DD hh:mm:ss');
    if(lastActivityTime?.length > 0){
        var diffInMinutes = moment(currentDateTime?.toString()).diff(moment(lastActivityTime?.toString()), 'minutes');
        if(diffInMinutes > 60){
            logout();
        }
    }
  },[])

  useEffect(()=>{
    // console.log(state);
  },[state])

  useEffect(()=>{
    checkAccountStatus(lang,userId,token,logout)
  },[])

  const { getRemainingTime,start,pause,getLastActiveTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    startManually:true,
    timeout: 60_000*60,
    throttle: 500,
  
  })

  useEffect(() => {
    if (localStorage?.getItem('loginStatus')=='true' && isBrowser==true) {
      start()
    } else {
      pause()
    }
  }, [ isLoggedin ])
 
  useEffect(() => {
      const interval = setInterval(() => {
        if(localStorage.getItem('loginStatus')=='false')
        clearInterval(interval);
        else{
            setRemaining(Math.ceil(getRemainingTime() / 1000))
            let lastActivityTime = moment().format('YYYY-MM-DD hh:mm:ss');
            localStorage.setItem("lastActivityTime",lastActivityTime);
        }
      }, 500)
      return () => {
        clearInterval(interval)
      }
  });

  useEffect(()=>{
    console.log("Remaiining",remaining);
    console.log(isMobile);
  },[remaining])

 
  return (
    <>
    </>
  );
}
export default UserIdleTimer;
