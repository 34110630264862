import React from 'react'
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import IQILogo from '../assets/icons/iqilogo.svg'
import Text from './typography/TextTypography';
import MailIcon from '../assets/icons/mail.svg'
import PhoneIcon from '../assets/icons/phone.svg'
import JCBPay from '../assets/icons/jcb.svg'
import VNpayIcon from '../assets/icons/vnpay.svg'
import MasterCardIcon from '../assets/icons/mastercard.svg'
import VisaIcon from '../assets/icons/visa.svg'
import { useLanguage } from '../hooks/languageContext';
import { useHistory } from 'react-router';
import '../styles/style.css'
import Certificate1 from '../assets/img/certificate1.png'
import Certificate2 from '../assets/img/certificate2.png'
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import AppstoreIcon from '../assets/img/appstore.jpeg';
import PlaystoreIcon from '../assets/img/playstore.jpeg';
const Footer = () => {
    const { lang, langContent } = useLanguage()
    const history = useHistory()
    const theme = useTheme()
    const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));
    const goToDoctorLogin = () => {
        // window.location.href = ('https://www.iqihealth.net/doctor_login_vn.html')
        window.open('https://www.iqihealth.net/doctor_login_vn.html', '_blank');

    }
    return (
        <React.Fragment>
            <Box mt={10} bgcolor="#F9FAFB" p={5} display={matchesSMDown ? 'none' : 'inherit'}>
                <Box mb={5}>
                    <Divider variant="fullWidth" />
                </Box>
                <Grid container spacing={10} >
                    <Grid item md={3} sm={12}>
                        <Box display="flex" flexDirection="column" >
                            <Box textAlign="center">
                                <img src={IQILogo} alt="IQI logo" />
                            </Box>
                            <Box mt={3} ml={0}>
                                <Text variant="textxs" fontWeight={400} style={{ alignSelf: 'stretch' }}>
                                    {langContent.about_iqi}
                                </Text>

                            </Box>
                            <Box mt={3}>
                                <Box style={{ cursor: 'pointer' }}>
                                    <img src={PlaystoreIcon} alt="download from playstore" height={35}
                                        onClick={() => window.location.replace('https://play.google.com/store/apps/details?id=app.iqihealth.android', '_blank')}
                                    />
                                </Box>
                                <Box mt={1} style={{ cursor: 'pointer' }}>
                                    <img src={AppstoreIcon} alt="download from appstore" height={42.5}
                                        onClick={() => window.location.replace('https://apps.apple.com/nz/app/iqi-health/id1603437175', '_blank')}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid container item md={9} sm={12} xs={12} spacing={1}>

                        <Grid item md={3} sm={3} xs={6}>
                            <Box display="flex" flexDirection="column">
                                <Box>
                                    <Text variant="textsm" fontWeight={600} style={{ opacity: .6, letterSpacing: '.8px', }}>
                                        {langContent.for_patients}
                                    </Text>
                                </Box>
                                <Box mt={2}>
                                    <Box>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push('/')}>
                                            {langContent.home}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push('/login')}>
                                            {langContent.login}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push('/')}>
                                            {langContent.featured_doctor_footer}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push('/')}>
                                            {langContent.nearby_hospitals}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push('/')}>
                                            {langContent.health_packages_footer}
                                        </Text>
                                    </Box>

                                </Box>

                            </Box>
                        </Grid>
                        <Grid item md={3} sm={3} xs={6}>
                            <Box display="flex" flexDirection="column">
                                <Box>
                                    <Text variant="textsm" fontWeight={600} style={{ opacity: .6, letterSpacing: '.8px', }}>

                                        {langContent.for_doctors}
                                    </Text>
                                </Box>
                                <Box mt={2}>
                                    <Box>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => goToDoctorLogin()}>
                                            {langContent.appointments}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => goToDoctorLogin()}>
                                            {langContent.doctor_login}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => goToDoctorLogin()}>
                                            {langContent.register}
                                        </Text>
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid item md={3} sm={3} xs={6}>
                            <Box display="flex" flexDirection="column">
                                <Box>
                                    <Text variant="textsm" fontWeight={600} style={{ opacity: .6, letterSpacing: '.8px', }}>
                                        {langContent.company}
                                    </Text>
                                </Box>
                                <Box mt={2}>
                                    <Box>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push('/about_us')}>
                                            {langContent.about_us}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push('/contact_us')}>
                                            {langContent.contact_us}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push('/partner_with_us')}>
                                            {langContent.partner_with_us}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push(`/payment_policy/${lang}`)}>
                                            {langContent.payment_policy}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push(`/refund_policy/${lang}`)} >
                                            {langContent.return_and_refund_policy}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push(`/cancellation_policy/${lang}`)} >
                                            {langContent.cancellation_policy}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push(`/privacy_policy/${lang}`)} >
                                            {langContent.privacy_policy}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push(`/terms_and_conditions/${lang}`)}>
                                            {langContent.terms_and_conditions}
                                        </Text>
                                    </Box>
                                    <Box mt={1}>
                                        <Text variant="textxs" style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)', cursor: 'pointer' }} onClick={() => history.push(`/disclaimer/${lang}`)}>
                                            {langContent.disclaimer}
                                        </Text>
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid item md={3} sm={3} xs={6}>
                            <Box display="flex" flexDirection="column">
                                <Box>
                                    <Text variant="textsm" fontWeight={600} style={{ opacity: .6, letterSpacing: '.8px', }}>
                                        {langContent.contact_footer}
                                    </Text>
                                </Box>
                                <Box mt={2}>
                                    <Box>

                                        {lang === 'en' ?
                                            <Box>
                                                <Text variant='textxs' style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)' }}>
                                                    Head office: 87 Tran Thien Chanh Street, Ward 12, District 10, Ho Chi Minh City, Vietnam
                                                </Text>
                                                <Text variant='textxs' style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)' }}>
                                                    Branch office: 240 3/2 Street, Ward 12, District 10, Ho Chi Minh City, Vietnam.

                                                </Text>
                                            </Box>
                                            :

                                            <Box>
                                                <Text variant='textxs' style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)' }}>
                                                    Trụ sở chính: 87 Đường Trần Thiện Chánh, Phường 12, Quận 10, Thành phố Hồ Chí Minh, Việt Nam.
                                                </Text>
                                                <Text variant='textxs' style={{ letterSpacing: '.8px', opacity: .8, color: 'rgba(13, 35, 68, 0.59)' }}>
                                                    Văn phòng chi nhánh: 240 Đường 3/2, Phường 12, Quận 10, Thành phố Hồ Chí Minh, Việt Nam.

                                                </Text>
                                            </Box>


                                        }

                                    </Box>
                                    <Box >
                                        <Box display="flex" alignItems="center">
                                            <Box>
                                                <img src={PhoneIcon} alt="Mail Icon" height={30} style={{ alignSelf: 'center', marginTop: '3px', color: 'white', height: '25px' }} />

                                            </Box>
                                            <Box ml={1}>
                                                <Text variant="textxs" fontWeight={400} style={{
                                                    alignSelf: 'stretch', color: '#1971F5', letterSpacing: '.8px',
                                                    opacity: .8
                                                }}>
                                                    028 71098968

                                                </Text>
                                            </Box>
                                        </Box>
                                        <Box mt={1}>
                                            <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
                                            <Box display="flex" alignItems="center">
                                                <Box>
                                                    <img src={MailIcon} alt="Mail Icon" height={30} style={{ alignSelf: 'center', marginTop: '3px' }} />

                                                </Box>
                                                <Box ml={1}>

                                                    <Text variant="textsm" style={{
                                                        alignSelf: 'stretch', color: '#1971F5'
                                                    }}>
                                                        support@e-stemmed.com

                                                    </Text>
                                                </Box>


                                            </Box>
                                            <Box mt={1} display="flex" style={{ cursor: 'pointer' }}>
                                                <Box onClick={() => window.open('https://www.facebook.com/IQiHealth-K%E1%BA%BFt-n%E1%BB%91i-v%E1%BB%9Bi-B%C3%A1c-s%C4%A9-c%E1%BB%A7a-b%E1%BA%A1n-100849888429528/', '_blank')}>


                                                    <FacebookIcon

                                                        style={{ color: '#4267B2', fontSize: 30 }}
                                                    />

                                                </Box>
                                                <Box ml={1} onClick={() => window.open('https://www.linkedin.com/company/iqihealth-enhancement-of-community-medical-experience', '_blank')} style={{ cursor: 'pointer' }}>


                                                    <LinkedInIcon


                                                        style={{ color: '#0077b5', fontSize: 30 }}
                                                    />

                                                </Box>
                                                <Box ml={1} onClick={() => window.open('https://www.youtube.com/channel/UCzY3xhXoPcjA9wOGJKckp7A', '_blank')} style={{ cursor: 'pointer' }}>


                                                    <YouTubeIcon

                                                        style={{ color: '#FF0000', fontSize: 30 }}

                                                    />

                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item md={3} >
                        <Box display="flex" flexDirection="column">

                            <Box mt={3}>
                                <Text variant="textxs" fontWeight={600} style={{ alignSelf: 'stretch' }}>
                                    {langContent.payment_options}
                                </Text>
                                <Box display="flex" alignItems="center" mt={2}>
                                    <Box >
                                        <img src={VisaIcon} alt="Visa Icon" height={40} style={{ alignSelf: 'center', marginTop: '3px' }} />

                                    </Box>
                                    <Box ml={1}>
                                        <img src={MasterCardIcon} alt="Mastercard Icon" height={50} style={{ alignSelf: 'center', marginTop: '3px' }} />

                                    </Box>
                                    <Box ml={1}>
                                        <img src={JCBPay} alt="JCB Pay Icon" height={50} style={{ alignSelf: 'center', marginTop: '3px' }} />

                                    </Box>
                                    <Box ml={1}>
                                        <img src={VNpayIcon} alt="Mail Icon" height={50} style={{ alignSelf: 'center', marginTop: '3px' }} />

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={12}>
                        <Box display="flex" justifyContent="center">
                            <Box>
                                <Box mb={2} textAlign="center" justifyContent="center" display="flex" >
                                    <img src={Certificate1} alt="certificate" style={{ objectFit: 'contain', height: '4rem', width: '10rem', cursor: 'pointer' }} onClick={() =>
                                        window.open('http://online.gov.vn/Home/WebDetails/86855', '_blank')} />
                                    <img src={Certificate2} alt="certificate"
                                        onClick={() =>
                                            window.open('http://online.gov.vn/Home/WebDetails/86855', '_blank')}
                                        style={{ objectFit: 'contain', height: '4rem', width: '10rem', marginLeft: '1rem', cursor: 'pointer' }} />

                                </Box>
                                <Divider style={{ height: '2px' }} />
                                <Box display="flex" justifyContent="center" >

                                    <Text variant="textxs" fontWeight={400} style={{ color: 'rgba(13, 35, 68, 0.6)', letterSpacing: '.8px', marginTop: '1rem' }}>
                                        {langContent.copyright}
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </React.Fragment >
    )
}
export default Footer