/* eslint-disable no-unused-vars */


import React, { useEffect } from "react";
import { useLanguage } from "../../hooks/languageContext";
import Header from "../Header";
import Footer from "../Footer";
import ThemeLoader from "../../utils/Loader";
import SomethingWentWrong from "../../screens/error/SomethingWentWrong";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import DOBSelect from "../formElements/DOBSelect";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  optionGender,
  optionGenderVn,
  optionMarital,
  optionMaritalVn,
  yearListEn,
} from "../../static";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {MedicalHistoryFormValidation } from "../../utils/validation";
import { getUserData, getToken } from "../../utils/utils";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import ThemeSelectWithSelect from "../formElements/ThemeSelectWithSelect";
import ThemeInput from "../formElements/ThemeInput";
import ThemeInputMobile from "../formElements/themeInputMobile";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Text from '../typography/TextTypography';
import { getMemberDetails, updateMedicalHistoryForm,getMedicalHistoryFormData } from "../../request";
import moment from "moment";
import Stack from '@mui/material/Stack';
import NewFooter from "../NewFooter";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Grid,
  FormControl,
  Divider,
  FormHelperText,
  useTheme,
  InputLabel,
  useMediaQuery,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import Alert from '@mui/material/Alert';
import { Label } from "@material-ui/icons";

function MedicalHistoryForm() {
  const history = useHistory();
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    bgNone:{
      backgroundColor:'none !important'
    },
    inputHeight:{
      height:'44px',
      padding:'10px'

    },
  }));
  const [requestPending, setRequestPending] = useState(false)
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const memberID = query.get('memberID')
  const redirected = query.get('redirected')
  const { lang, langContent } = useLanguage();
  const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const dateObj = yearListEn(lang);
  const yearArray = dateObj.year;
  const dayArray = dateObj.day;
  const monthArray = dateObj.month;
  const userId = getUserData("user_id");
  const memberId = query.get("memberID");
  const token = getToken();
  const classes = useStyles();
  const [dob, setDob] = useState('');
  const [dobDay, setDobDay] = useState('');
  const [dobMonth, setDobMonth] = useState('');
  const [dobYear, setDobYear] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [patientName, setPatientName] = useState('');
  const [diseaseDesc, setDiseaseDesc] = useState('');
  const [abnormal_findings, set_abnormal_findings] = useState('');
  const [surgery_before, set_surgery_before] = useState('');
  const [surgery_before_desc, set_surgery_before_desc] = useState('');
  const [drug_allergy, set_drug_allergy] = useState('');
  const [drug_allergy_desc, set_drug_allergy_desc] = useState('');
  const [current_medication, set_current_medication] = useState('');
  const [smoking, set_smoking] = useState('');
  const [smoking_year, set_smoking_year] = useState('');
  const [alcohol, set_alcohol] = useState('');
  const [marital_status, set_marital_status] = useState('');
  const [reproductive_history, set_reproductive_history] = useState('');
  const [regular_periods, set_regular_periods] = useState('');
  const [pregnant, set_pregnant] = useState('');
  const [menopause_age, set_menopause_age] = useState('');
  const [present_symptoms, set_present_symptoms] = useState('');
  const [ent, set_ent] = useState('');
  const [eye, set_eye] = useState('');
  const [dental_care, set_dental_care] = useState('');
  const [dermatology, set_dermatology] = useState('');
  const [grandparents, set_grandparents] = useState('');
  const [parents, set_parents] = useState('');
  const [siblings, set_siblings] = useState('');
  const [sign_full_name, set_sign_full_name] = useState('');
  const [diseaseArray,setDiseaseArray] = useState([0,0,0,0,0,0,0,0,0,0,0,0])
  const [tempArray,setTempArray]=useState([])
  const [validationSchema, setValidationSchema] = useState(MedicalHistoryFormValidation);
  const [MemberDetails,setMemberDetails] =useState([])
  const [diseaseContent,setDiseaseContent] = useState([
    {
      content: langContent.Stroke,
      isChecked:false,
    },
    {
      content:langContent.Hypertension,
      isChecked:false,
    },
    {
      content:langContent.heart_disease ,
      isChecked:false,
    },
    {
      content:langContent.stomach_ulcers ,
      isChecked:false,
    },
    {
      content: langContent.Hepatitis ,
      isChecked:false,
    },
    {
      content: langContent.Diabetes ,
      isChecked:false,
    },
    {
      content: langContent.lung_disease,
      isChecked:false,
    },
    {
      content: langContent.Tuberculosis ,
      isChecked:false,
    },
    {
      content: langContent.thyroid_disease ,
      isChecked:false,
    },
    {
      content: langContent.Epilepsy,
      isChecked:false,
    },
    {
      content:langContent.kidney_disease,
      isChecked:false,
    },
    {
      content:langContent.Cancer ,
      isChecked:false,
    },
  ]);
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleChange = (ev) => {
    console.log(ev.target.value);
  };
  const additionalInfoOnVatChanged = (ev)=>{
    console.log(ev.target.value);
  }

  const onSubmit = (data) => {
    setIsLoading(true);
    const memberDob =
      data.member_dob_year +
      "/" +
      data.member_dob_month +
      "/" +
      data.member_dob_day;
    const consent_date =
      data.member_consent_year +
      "/" +
      data.member_consent_month +
      "/" +
      data.member_consent_day;
    data.country_code = "+84";
    const fd = new FormData();

    fd.append("member_id", memberID);
    fd.append("patient_name", data.patient_name);
    fd.append("dob", memberDob ??  '');
    fd.append("age", data.patient_age ??  '');
    fd.append("gender", data.member_gender ??  '');
    fd.append("marital_status", data.member_married_status ??  '');
    fd.append("diseases", diseaseArray ??  '');
    fd.append("disease_desc", diseaseDesc??  '');
    fd.append("abnormal_findings", abnormal_findings??  '');
    fd.append("surgery_before", surgery_before??  '');
    fd.append("surgery_before_desc", surgery_before_desc??  '');
    fd.append("drug_allergy", drug_allergy ??  '');
    fd.append("drug_allergy_desc", drug_allergy_desc??  '');
    fd.append("current_medication", current_medication??  '');
    fd.append("smoking", smoking??  '');
    fd.append("smoking_year", smoking_year??  '');
    fd.append("alcohol", alcohol??  '');
    fd.append("marital_status", marital_status??  '');
    fd.append("reproductive_history", reproductive_history??  '');
    fd.append("regular_periods", regular_periods??  '');
    fd.append("pregnant", pregnant??  '');
    fd.append("menopause_age", menopause_age??  '');
    fd.append("present_symptoms", present_symptoms??  '');
    fd.append("ent", ent ??  '');
    fd.append("eye", eye ??  '');
    fd.append("dental_care", dental_care ??  '');
    fd.append("dermatology", dermatology ??  '');
    fd.append("grandparents", grandparents ??  '');
    fd.append("parents", parents ??  '');
    fd.append("siblings", siblings ??  '');
    fd.append("sign_full_name", sign_full_name ??  '');
    updateMedicalHistoryForm(fd, token, setIsLoading, langContent, history, setRequestPending,memberID,redirected)

  };

  const skipForm = (ev) => {
    if(redirected=='yes'){
      history.push('/cart');
    }else{
      history.push('/my_forms');
    }
  };

  useEffect(()=>{
    calculateAge()
  },[dobDay,dobMonth,dobYear])

  const calculateAge = () => {
    let udobDate = (dobYear+'-'+dobMonth+'-'+dobDay).toString();
    const dobDate = new Date(udobDate);
    const today = new Date();
      const birthDateObj = new Date(dobDate);
      let age = today.getFullYear() - birthDateObj.getFullYear();
      const monthDiff = today.getMonth() - birthDateObj.getMonth();

      // If birth month is after current month or birth day is after current day, subtract 1 from age
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
      }

      // return age
    setAge(age);
  };

  useEffect(()=>{
    let newValue = [
      {
        content: langContent.Stroke,
        isChecked:false,
      },
      {
        content:langContent.Hypertension,
        isChecked:false,
      },
      {
        content:langContent.heart_disease ,
        isChecked:false,
      },
      {
        content:langContent.stomach_ulcers ,
        isChecked:false,
      },
      {
        content: langContent.Hepatitis ,
        isChecked:false,
      },
      {
        content: langContent.Diabetes ,
        isChecked:false,
      },
      {
        content: langContent.lung_disease,
        isChecked:false,
      },
      {
        content: langContent.Tuberculosis ,
        isChecked:false,
      },
      {
        content: langContent.thyroid_disease ,
        isChecked:false,
      },
      {
        content: langContent.Epilepsy,
        isChecked:false,
      },
      {
        content:langContent.kidney_disease,
        isChecked:false,
      },
      {
        content:langContent.Cancer ,
        isChecked:false,
      },
    ];
    setDiseaseContent(newValue);
    getMedicalHistoryFormData(userId, lang, memberId, token, setMemberDetails, setIsLoading, setResponseError)

  },[lang])

  useEffect(()=>{
    setDob(MemberDetails?.member_dob)
    setDobDay(MemberDetails?.member_dob_day)
    setDobMonth(MemberDetails?.member_dob_month)
    setDobYear(MemberDetails?.member_dob_year)
    setGender(MemberDetails?.member_gender)
    setPatientName(MemberDetails?.member_fname)
    setDiseaseDesc(MemberDetails?.disease_desc)
    set_abnormal_findings(MemberDetails?.abnormal_findings)
    set_surgery_before(MemberDetails?.surgery_before)
    set_surgery_before_desc(MemberDetails?.surgery_before_desc)
    set_drug_allergy(MemberDetails?.drug_allergy)
    set_drug_allergy_desc(MemberDetails?.drug_allergy_desc)
    set_current_medication(MemberDetails?.current_medication)
    set_smoking(MemberDetails?.smoking)
    set_smoking_year(MemberDetails?.smoking_year)
    set_alcohol(MemberDetails?.alcohol)
    set_marital_status(MemberDetails?.marital_status)
    set_reproductive_history(MemberDetails?.reproductive_history)
    set_regular_periods(MemberDetails?.regular_periods)
    set_pregnant(MemberDetails?.pregnant)
    set_menopause_age(MemberDetails?.menopause_age)
    set_present_symptoms(MemberDetails?.present_symptoms)
    set_ent(MemberDetails?.ent)
    set_eye(MemberDetails?.eye)
    set_dental_care(MemberDetails?.dental_care)
    set_dermatology(MemberDetails?.dermatology)
    set_grandparents(MemberDetails?.grandparents)
    set_parents(MemberDetails?.parents)
    set_siblings(MemberDetails?.siblings)
    set_sign_full_name(MemberDetails?.sign_full_name)
    setTempArray(MemberDetails?.diseases)

  },[MemberDetails]);

  useEffect(()=>{
    tempArray?.length && console.log(tempArray);
    if(tempArray?.length){
        let values =[...tempArray].map(Number);
        setDiseaseArray(values)
    }
  },[tempArray])

  useEffect(()=>{
    if(diseaseArray?.length){
      diseaseContent.forEach((item,index)=>{
        if(diseaseArray[index]==1){
          item.isChecked = true
        }else{
          item.isChecked=false;
        }
      });
      const values = [...diseaseContent];
      setDiseaseContent(values)
    }
  },[diseaseArray])

  const handleCheckboxChange=(e,index)=>{
    const values = [...diseaseContent];
    values[index].isChecked = e.target.checked;
    setDiseaseContent(values);
    if(e.target.checked ==true){
      const temp = [...diseaseArray]
      temp[index] =1;
      setDiseaseArray(temp)
    }else{
      const temp = [...diseaseArray]
      temp[index] =0;
      setDiseaseArray(temp)
    }
  }

  useEffect(()=>{
    console.log(diseaseContent);
  },[diseaseContent])

  return (
    <>
      <React.Fragment>
        <Header shouldHaveBackButton={true} shouldHaveSearchBar={false} />
        {isLoading ? (
          <ThemeLoader />
        ) : responseError === true ? (
          <SomethingWentWrong />
        ) : (
          <Container>
            <Box textAlign="center" mt={6}>
             <Typography variant="h2" component="h2">
                {langContent.medical_history_form}
              </Typography>
            </Box>
            <Box>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                <Button variant="contained" color="primary" onClick={skipForm} style={{ color: "white",width:'95px',marginBottom:'1rem' }} >
                  {langContent.skip}
                </Button>
                <Alert style={{backgroundColor:'rgb(229, 246, 253)'}}  severity="info">{langContent.if_you_skip}</Alert>
                </Grid>
              </Grid>
            </Box>
            <Box
              pt={matchesSMDown ? 2 : 4.7}
            >
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Accordion expanded={true} style={{padding:'1rem'}}>
                  <AccordionDetails>
                    <Box>
                      <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                        <Grid item md={6} style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                            <InputLabel htmlFor="patient_name">{langContent.patient_name}</InputLabel>
                        </Grid>
                        <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                          <ThemeInput onChange={e => setPatientName(e.target.value)} size="small" type="text" value={patientName ?? ""} name='patient_name' id="patient_name" register={register} errors={errors} fullWidth />
                        </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={matchesSMDown ? 3 : 1}>
                        <Grid item  md={6} sm={12} xs={12}>
                          <Grid
                            item
                            md={6}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="user_dob">
                              {langContent.date_of_birth}{""}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item md={4} sm={4} xs={4}>
                              <FormControl fullWidth>
                                <DOBSelect
                                  size="small"
                                  name="member_dob_day"
                                  label={langContent.day}
                                  register={register}
                                  errors={errors}
                                  valueKey="key"
                                  fullWidth
                                  optionObj={dayArray}
                                  labelKey="label"
                                  selectLabel="select"
                                  value={dobDay ?? ""}
                                  onChange={e=>{setDobDay(e.target.value)}}

                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} sm={4} xs={4}>
                              <FormControl fullWidth>
                                <ThemeSelectWithSelect
                                  size="small"
                                  name="member_dob_month"
                                  label={langContent.month}
                                  register={register}
                                  errors={errors}
                                  valueKey="key"
                                  fullWidth
                                  optionObj={monthArray}
                                  labelKey="label"
                                  value={dobMonth ?? ""}
                                  onChange={e=>{setDobMonth(e.target.value)}}

                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} sm={4} xs={4}>
                              <FormControl fullWidth>
                                <ThemeSelectWithSelect
                                  size="small"
                                  name="member_dob_year"
                                  label={langContent.year}
                                  register={register}
                                  errors={errors}
                                  valueKey="key"
                                  fullWidth
                                  optionObj={yearArray}
                                  labelKey="label"
                                  value={dobYear ?? ""}
                                  onChange={e=>{setDobYear(e.target.value)}}

                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={3} md={3} xs={12}>
                          <Grid
                            item
                            md={6}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="patient_age">
                              {langContent.age}{""}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInput
                              size="small"
                              id="patient_age"
                              name="patient_age"
                              register={register}
                              errors={errors}
                              fullWidth
                              isRequired={true}
                              value={age}
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange ={(e)=>{setAge(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          md={3}
                          sm={12}
                          xs={12}
                          style={{ marginTop: ".6rem", marginBottom: ".3rem" }}
                        >
                          <Grid item md={6} sm={12}>
                            <InputLabel htmlFor="member_gender">
                              {langContent.gender}{""}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeSelectWithSelect
                              name="member_gender"
                              id="member_gender"
                              register={register}
                              size="small"
                              errors={errors}
                              fullWidth
                              optionObj={
                                lang === "en" ? optionGender : optionGenderVn
                              }
                              value={gender ?? ""}
                              onChange={e=>{setGender(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={5}>
                      <Grid container>
                        <Grid item md={12} lg={12} sm={12}>
                              <Typography variant="h5">
                                {langContent?.in_order_to_let_your}
                              </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={3}>
                      <Grid container>
                        <Grid item  md={12} lg={12} sm={12}>
                          <Alert icon={false} severity="info" style={{backgroundColor:'rgb(229, 246, 253)'}}>
                            <Typography variant="h5" >
                              {langContent?.do_you_now_or_ever_had+'?'}
                            </Typography>
                          </Alert>
                          </Grid>
                          <Grid item md={12} style={{marginTop:'1rem'}}>
                            <Grid container>
                              {
                                diseaseContent.map((item,index)=>(
                                  <Grid item md={3} lg={3} sm={12} xs={12}>
                                    <Box mb={3}>
                                    <Typography variant="subtitle2">
                                      <span>{item.content}</span> <Checkbox checked={item.isChecked} value={item.isChecked}  onChange={e=>handleCheckboxChange(e,index)} key={index} style={{float:'inline-end',paddingRight:'2rem',paddingBottom:0,paddingTop:0}}/>
                                    </Typography>
                                    </Box>
                                  </Grid>
                                ))
                              }
                            </Grid>
                            <Grid container style={{marginTop:'1.4rem'}}>
                              <Grid item md={12} sm={12} >
                                <Typography variant="h6">
                                  {langContent.other_condition_or_more_information}
                                </Typography>
                                <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                  <ThemeInput onChange={e => setDiseaseDesc(e.target.value)} size="small" type="text" value={diseaseDesc ?? ""} name='diseaseDesc' id="diseaseDesc" register={register} errors={errors} fullWidth />
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container style={{marginTop:'1.4rem'}}>
                              <Grid item md={12} sm={12}>
                                <Typography variant="h6">
                                  {langContent.abnormal_findings_in_previous}
                                </Typography>
                                <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                  <ThemeInput onChange={e => set_abnormal_findings(e.target.value)} size="small" type="text" value={abnormal_findings ?? ""} name='abnormal_findings' id="abnormal_findings" register={register} errors={errors} fullWidth />
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container style={{marginTop:'1.4rem'}}>
                              <Grid item md={12} sm={12}>
                                <Grid container>
                                  <Grid item md={6}>
                                    <Typography variant="h6">
                                    {langContent.have_you_ever_had_any}
                                  </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                  <RadioGroup
                                      row
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      value={surgery_before}
                                      name="direct_billing_service"
                                      onChange={e=>{set_surgery_before(e.target.value)}}
                                      style={{paddingLeft:matchesSMDown ? '1.9rem':'0px',float:'right'}}
                                    >
                                    <FormControlLabel
                                      value="yes"
                                      control={<Radio size="small" />}
                                      label={langContent.yes}
                                    />
                                    <FormControlLabel
                                      value="no"
                                      control={<Radio  size="small" />}
                                      label={langContent.no}
                                    />
                                  </RadioGroup>
                                  </Grid>
                                </Grid>
                                <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                  <ThemeInput onChange={e => {set_surgery_before_desc(e.target.value)}} size="small" type="text" value={surgery_before_desc ?? ""} name='surgery_before_desc' id="surgery_before_desc" register={register} errors={errors} fullWidth />
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container style={{marginTop:'1.4rem'}}>
                              <Grid item md={12} sm={12}>
                                <Grid container>
                                  <Grid item md={6}>
                                    <Typography variant="h6">
                                      {langContent.drug_allergies}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      value={drug_allergy}
                                      name="direct_billing_service"
                                      onChange={e=>{set_drug_allergy(e.target.value)}}
                                      style={{paddingLeft:matchesSMDown ? '1.9rem':'0px',float:'right'}}
                                    >
                                    <FormControlLabel
                                      value="yes"
                                      control={<Radio size="small" />}
                                      label={langContent.yes}
                                    />
                                    <FormControlLabel
                                      value="no"
                                      control={<Radio  size="small" />}
                                      label={langContent.no}
                                    />
                                   </RadioGroup>
                                  </Grid>
                                </Grid>
                                <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                  <ThemeInput onChange={e => {set_drug_allergy_desc(e.target.value)}} size="small" type="text" value={drug_allergy_desc ?? ""} name='drug_allergy_desc' id="drug_allergy_desc" register={register} errors={errors} fullWidth />
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container style={{marginTop:'1.4rem'}}>
                              <Grid item md={12} sm={12}>
                                <Typography variant="h6">
                                  {langContent.current_medication}
                                </Typography>
                                  <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                    <ThemeInput onChange={e => {set_current_medication(e.target.value)}} size="small" type="text" value={current_medication ?? ""} name='current_medication' id="current_medication" register={register} errors={errors} fullWidth />
                                  </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container style={{marginTop:'1rem'}}>
                              <Grid item md={12} lg={12}>
                                <Container style={{border:'1px solid gray'}}>
                                  <Grid container style={{padding:'1rem 0'}}>
                                    <Grid item md={3}>
                                      <Typography variant="body1">
                                        <b>{langContent.Smoking}</b>
                                      </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={smoking}
                                        name="direct_billing_service"
                                        onChange={e=>{set_smoking(e.target.value)}}
                                        style={{paddingLeft:matchesSMDown ? '1.9rem':'0px'}}
                                      >
                                      <FormControlLabel
                                        value="never"
                                        control={<Radio size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.Never}
                                      />
                                      <FormControlLabel
                                        value="quitted"
                                        control={<Radio  size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.Quitted}
                                      />
                                      <FormControlLabel
                                        value="current"
                                        control={<Radio  size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.Current}
                                      />
                                      </RadioGroup>
                                    </Grid>
                                    <Grid item md={1}>
                                      <Typography variant="h6" style={{marginTop:'0.5rem'}}>{langContent.PPY}
                                      </Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                      {/* <TextField size="small" id="standard-basic" label={langContent.Years} variant="outlined" /> */}
                                      <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                        <ThemeInput onChange={e => set_smoking_year(e.target.value)} size="small" type="text" value={smoking_year ?? ""} name='smoking_year' id="smoking_year" register={register} errors={errors} fullWidth />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  <Grid container style={{padding:'1rem 0'}}>
                                    <Grid item md={3}>
                                      <Typography variant="body1">
                                       <b>{langContent.alcohol}</b> 
                                      </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={alcohol}
                                        name="direct_billing_service"
                                        onChange={e=>{set_alcohol(e.target.value)}}
                                        style={{paddingLeft:matchesSMDown ? '1.9rem':'0px'}}
                                      >
                                      <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.yes}
                                      />
                                      <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.no}
                                      />
                                      </RadioGroup>
                                    </Grid>
                                    <Grid item md={3}>
                                      <Typography variant="body1">
                                        <b>{langContent.marital_status}</b>
                                      </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={marital_status}
                                        name="direct_billing_service"
                                        onChange={e=>{set_marital_status(e.target.value)}}
                                        style={{paddingLeft:matchesSMDown ? '1.9rem':'0px'}}
                                      >
                                      <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.yes}
                                      />
                                      <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.no}
                                      />
                                      </RadioGroup>
                                    </Grid>
                                  </Grid>
                                  {/* women reproductive history */}
                                  <Grid container style={{padding:'1rem 0'}}>
                                    <Grid item md={3}>
                                      <Typography variant="body1">
                                       <b>{langContent.women_reproductive_history}</b>
                                      </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={reproductive_history}
                                        name="direct_billing_service"
                                        onChange={e=>{set_reproductive_history(e.target.value)}}
                                        style={{paddingLeft:matchesSMDown ? '1.9rem':'0px'}}
                                      >
                                      <FormControlLabel
                                        value="pregnancy"
                                        control={<Radio size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.pregnancies}
                                      />
                                      <FormControlLabel
                                        value="miscarriage"
                                        control={<Radio size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.miscarriages}
                                      />
                                      </RadioGroup>
                                    </Grid>
                                    <Grid item md={3}>
                                      <Typography variant="body1">
                                        <b>{langContent.regular_periods}</b>
                                      </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={regular_periods}
                                        name="direct_billing_service"
                                        onChange={e=>{set_regular_periods(e.target.value)}}
                                        style={{paddingLeft:matchesSMDown ? '1.9rem':'0px'}}
                                      >
                                      <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.yes}
                                      />
                                      <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.no}
                                      />
                                      </RadioGroup>
                                    </Grid>
                                  </Grid>
                                  <Grid container style={{padding:'1rem 0'}}>
                                    <Grid item md={3}>
                                      <Typography variant="body1">
                                       <b>{langContent.are_you_pregnant}</b>
                                      </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={pregnant}
                                        name="direct_billing_service"
                                        onChange={e=>{set_pregnant(e.target.value)}}
                                        style={{paddingLeft:matchesSMDown ? '1.9rem':'0px'}}
                                      >
                                      <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.yes}
                                      />
                                      <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" style={{paddingBottom:0,paddingTop:0}} />}
                                        label={langContent.no}
                                      />
                                      </RadioGroup>
                                    </Grid>
                                    <Grid item md={3}>
                                      <Typography variant="body1">
                                        <b>{langContent.have_you_reached_menopause}</b>
                                      </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                    <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                      <ThemeInput onChange={e => set_menopause_age(e.target.value)} size="small" type="text" value={menopause_age ?? ""} name='menopause_age' id="menopause_age" register={register} errors={errors} fullWidth />
                                    </FormControl>
                                    </Grid>
                                  </Grid>
                                </Container>
                              </Grid>
                              <Grid container style={{marginTop:'1.4rem'}}>
                                <Grid item md={12} sm={12}>
                                  <Typography variant="h6">
                                    {langContent.describe_briefly_your_present_symptoms}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {langContent.internal_medicine_heart}
                                  </Typography>
                                  <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                    <ThemeInput onChange={e => set_present_symptoms(e.target.value)} size="small" type="text" value={present_symptoms ?? ""} name='present_symptoms' id="present_symptoms" register={register} errors={errors} fullWidth />
                                  </FormControl>
                              </Grid>
                            </Grid>
                              <Grid container style={{marginTop:'1.4rem'}}>
                                <Grid item md={12} sm={12}>
                                  <Typography variant="h6">
                                    {langContent.ENT}
                                  </Typography>
                                  <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                    <ThemeInput onChange={e => set_ent(e.target.value)} size="small" type="text" value={ent ?? ""} name='ent' id="ent" register={register} errors={errors} fullWidth />
                                  </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container style={{marginTop:'1.4rem'}}>
                                <Grid item md={12} sm={12}>
                                  <Typography variant="h6">
                                    {langContent.Eye}
                                  </Typography>
                                  <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                    <ThemeInput onChange={e => set_eye(e.target.value)} size="small" type="text" value={eye ?? ""} name='eye' id="eye" register={register} errors={errors} fullWidth />
                                  </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container style={{marginTop:'1.4rem'}}>
                                <Grid item md={12} sm={12}>
                                  <Typography variant="h6">
                                    {langContent.dental_care}
                                  </Typography>
                                  <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                    <ThemeInput onChange={e => set_dental_care(e.target.value)} size="small" type="text" value={dental_care ?? ""} name='dental_care' id="dental_care" register={register} errors={errors} fullWidth />
                                  </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container style={{marginTop:'1.4rem'}}>
                                <Grid item md={12} sm={12}>
                                  <Typography variant="h6">
                                    {langContent.Dermatology}
                                  </Typography>
                                  <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                    <ThemeInput onChange={e => set_dermatology(e.target.value)} size="small" type="text" value={dermatology ?? ""} name='dermatology' id="dermatology" register={register} errors={errors} fullWidth />
                                  </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container style={{marginTop:'1.4rem'}}>
                                <Grid item md={12} sm={12}>
                                  <Typography variant="h6">
                                    {langContent.family_medical_conditions}
                                  </Typography>
                                  <Grid container spacing={matchesSMDown ? 3 : 1}>
                                      <Grid item md={4} spacing={2}>
                                        <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem', }}>
                                          <ThemeInput label={langContent.Grandparents} onChange={e => {set_grandparents(e.target.value)}} size="small" type="text" value={grandparents ?? ""} name='grandparents' id="grandparents" register={register} errors={errors} fullWidth />
                                        </FormControl>
                                      </Grid>
                                      <Grid item md={4}>
                                        <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                          <ThemeInput label={langContent.Parents} onChange={e => {set_parents(e.target.value)}} size="small" type="text" value={parents ?? ""} name='parents' id="parents" register={register} errors={errors} fullWidth />
                                        </FormControl>
                                      </Grid>
                                      <Grid item md={4}>
                                        <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                          <ThemeInput label={langContent.Siblings} onChange={e => {set_siblings(e.target.value)}} size="small" type="text" value={siblings ?? ""} name='siblings' id="siblings" register={register} errors={errors} fullWidth />
                                        </FormControl>
                                      </Grid>
                                  </Grid>
                              </Grid>
                            </Grid>
                            <Grid container style={{marginTop:'1.4rem'}}>
                                <Grid item md={12} sm={12}>
                                  <Typography variant="h6">
                                    {langContent.signature_and_full_name}
                                  </Typography>
                                  <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                                    <ThemeInput onChange={e => {set_sign_full_name(e.target.value)}} size="small" type="text" value={sign_full_name ?? ""} name='sign_full_name' id="sign_full_name" register={register} errors={errors} fullWidth />
                                  </FormControl>
                              </Grid>
                              <Grid item md={12} sm={12} xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      mt={7.8}
                      mb={2.6}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={requestPending ? true : false}
                        color="primary"
                        style={{ padding: "8px 22px", color: "white" }}
                      >
                        {requestPending && (
                          <>
                            <CircularProgress color="primary" size={22} />
                            <Text
                              variant="textxs"
                              color="#02abd6"
                              style={{ marginLeft: "10px" }}
                            >
                              {langContent.loading}..
                            </Text>
                          </>
                        )}
                        {!requestPending && langContent.submit_form}
                      </Button>
                    </Box>
                  </Grid>
                            </Grid>
                            </Grid>
                          </Grid>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </form>
            </Box>
          </Container>
        )}
        {!isLoading && <NewFooter />}
      </React.Fragment>
    </>
  );
}

export default MedicalHistoryForm;
