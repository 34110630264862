import * as Yup from 'yup';
import content from './language';
import { useState, useEffect } from 'react';

const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'vn'
let langContent = ''

lang === "vn" ?
(langContent = content.vn) :
(langContent = content.English);

export const ValidationLanguageChanged = () =>{
     let langa = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'vn'
    langa === "vn" ?
    (langContent = content.vn) :
    (langContent = content.English);
}

export const LoginValidation = Yup.object().shape({
    user_mobile: Yup.string()
        .required(langContent.required_field)
        .test('len', langContent.mobile_number_validation, val => val && val.length >= 8 && val.length <= 11),
});
export const sendOTPValidation = Yup.object().shape({
    user_mobile: Yup.string()
        .required(langContent.required_field)
        .test('len', langContent.mobile_number_validation, val => val && val.length >= 8 && val.length <= 11),
});
export const SignupValidation = Yup.object().shape({
    user_fname: Yup.string()
        .required(langContent.required_field),
    user_email: Yup.string()
        .required(langContent.required_field)
        .email(langContent.invalid_email),
    user_gender: Yup.string()
        .required(langContent.required_field),
    user_city: Yup.string()
        .required(langContent.required_field),
    address_line_1: Yup.string()
        .required(langContent.required_field),
    address_district_id: Yup.string()
        .required(langContent.required_field),
    address_type_id: Yup.string()
        .required(langContent.required_field),
    user_married_status: Yup.string()
        .required(langContent.required_field),
    user_dob_month: Yup.string()
        .required(langContent.required_field),
    user_dob_day: Yup.string()
        .required(langContent.required_field),
    user_dob_year: Yup.string()
        .required(langContent.required_field),
    user_country: Yup.string()
        .required(langContent.required_field)

})

export const ChangePasswordValidation = Yup.object().shape({
    current_password: Yup.string()
      .required(langContent.required_field),
    new_password: Yup.string()
      .required(langContent.required_field)
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        langContent.password_reset_info
      ),
    confirm_password: Yup.string()
      .required(langContent.required_field)
      .oneOf([Yup.ref('new_password')], langContent.passwords_do_not_match),
  });

export const ChangePasswordValidation1 = Yup.object().shape({
    new_password: Yup.string()
        .required(langContent.required_field).matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            langContent.password_reset_info
        ),
    confirm_password: Yup.string()
        .required(langContent.required_field)
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            langContent.password_reset_info
        ),
})

export const addMemberValidation = Yup.object().shape({
    member_fname: Yup.string()
        .required(langContent.required_field),
    member_relationship: Yup.string()
        .required(langContent.required_field),
    member_mobile: Yup.string()
        .required(langContent.required_field),
    idproof_no: Yup.string()
        .required(langContent.required_field),
    member_gender: Yup.string()
        .required(langContent.required_field),
    member_dob_month: Yup.string()
        .required(langContent.required_field),
    member_dob_day: Yup.string()
        .required(langContent.required_field),
    member_dob_year: Yup.string()
        .required(langContent.required_field),
    member_married_status: Yup.string()
        .required(langContent.required_field),
    member_city: Yup.string()
        .required(langContent.required_field),
    address_district_id: Yup.string()
        .required(langContent.required_field),
    member_country: Yup.string()
        .required(langContent.required_field)

})
export const addForm1AValidation1 = Yup.object().shape({
    patient_name: Yup.string().required(langContent.required_field),
    member_married_status: Yup.string().required(langContent.required_field),
    patient_cell_phone_no: Yup.number().required(langContent.required_field),
    social_security_number: Yup.string().required(langContent.required_field),
    patient_name: Yup.string().required(langContent.required_field),
    patient_age: Yup.string().required(langContent.required_field),
    home_address: Yup.string().required(langContent.required_field),
    // zip_code: Yup.number().required(langContent.required_field).positive().integer(),
    member_dob_day: Yup.string().required(langContent.required_field),
    member_dob_month: Yup.string().required(langContent.required_field),
    member_dob_year: Yup.string().required(langContent.required_field),
    member_gender: Yup.string().required(langContent.required_field),
});

export const addForm1AValidation2 = Yup.object().shape({
    patient_name: Yup.string().required(langContent.required_field),
    // social_security_number: Yup.string().required(langContent.required_field),
    member_married_status: Yup.string().required(langContent.required_field),
    patient_cell_phone_no: Yup.number().required(langContent.required_field),
    // patient_home_phone_no: Yup.number().required(langContent.required_field),
    patient_name: Yup.string().required(langContent.required_field),
    patient_age: Yup.string().required(langContent.required_field),
    home_address: Yup.string().required(langContent.required_field),
    // zip_code: Yup.number().required(langContent.required_field).positive().integer(),
    name_of_patient_guardian: Yup.string().required(langContent.required_field),
    relation_to_patient: Yup.string().required(langContent.required_field),
    member_dob_day: Yup.string().required(langContent.required_field),
    member_dob_month: Yup.string().required(langContent.required_field),
    member_dob_year: Yup.string().required(langContent.required_field),
    member_gender: Yup.string().required(langContent.required_field),
});

export const addForm1BValidation = Yup.object().shape({
    patient_name: Yup.string().required(langContent.required_field),
    member_married_status: Yup.string().required(langContent.required_field),
    member_dob_day: Yup.string().required(langContent.required_field),
    member_dob_month: Yup.string().required(langContent.required_field),
    member_dob_year: Yup.string().required(langContent.required_field),
    member_gender: Yup.string().required(langContent.required_field),
    social_security_number: Yup.string().required(langContent.required_field),
    pri_insurance_card_no: Yup.string().required(langContent.required_field),
    primary_policy_holder: Yup.string().required(langContent.required_field).nullable(),
})

export const MedicalHistoryFormValidation = Yup.object().shape({
    patient_name: Yup.string().required(langContent.required_field),
    patient_age: Yup.string().required(langContent.required_field),
    member_dob_day: Yup.string().required(langContent.required_field),
    member_dob_month: Yup.string().required(langContent.required_field),
    member_dob_year: Yup.string().required(langContent.required_field),
    member_gender: Yup.string().required(langContent.required_field),
});

export const homeCareSurveyFormValidation = Yup.object().shape({
    question1: Yup.string().required(langContent.required_field),
    question2: Yup.string().required(langContent.required_field),
    question3: Yup.string().required(langContent.required_field),
    question4: Yup.string().required(langContent.required_field),
    question5: Yup.string().required(langContent.required_field),
    question6: Yup.string().required(langContent.required_field),
    question7: Yup.string().required(langContent.required_field),
    question8: Yup.string().required(langContent.required_field),
   
})

export const otpValidation = Yup.object().shape({
    otp_login: Yup.string()
        .required(langContent.please_enter_otp)
})
export const billingAddressValidation = Yup.object().shape({
    // user_fname: Yup.string()
    //     .required(langContent.required_field),
    // user_city: Yup.string()
    //     .required(langContent.required_field),
    // user_state: Yup.string()
    //     .required(langContent.required_field),
    // user_address: Yup.string()
    //     .required(langContent.required_field),
    // user_email: Yup.string()
    //     .required(langContent.required_field)
    //     .email(langContent.invalid_email),
    user_address_id: Yup.string()
        .required(langContent.required_field)
})
export const billingShippingAddressValidation = Yup.object().shape({
    user_fname: Yup.string()
        .required(langContent.required_field),
    user_city: Yup.string()
        .required(langContent.required_field),
    user_state: Yup.string()
        .required(langContent.required_field),
    user_address1: Yup.string()
        .required(langContent.required_field),
    user_email: Yup.string()
        .required(langContent.required_field)
        .email(langContent.invalid_email),
    user_Scity: Yup.string()
        .required(langContent.required_field),
    user_Sstate: Yup.string()
        .required(langContent.required_field),
    user_Saddress1: Yup.string()
        .required(langContent.required_field),
})
export const kitProductValidation = Yup.object().shape({
    select_kit: Yup.string().required(langContent.required_field),
})
export const addLabtestToCartValidations = Yup.object().shape({
    sample_collect_time: Yup.string()
        .required(langContent.required_field),
    member_id: Yup.string()
        .required(langContent.required_field),
    package_date: Yup.string().required(langContent.required_field).test('package_date', langContent.required_field, val => console.log(val))
})
export const addCovidPackageValidation = Yup.object().shape({
    select_package: Yup.string().required(langContent.required_field),
    clinic_id: Yup.string().required(langContent.required_field),
    member_id: Yup.string().required(langContent.required_field),
    sample_collect_time: Yup.string().required(langContent.required_field),
    package_date: Yup.mixed().test('package_date', langContent.required_field, val => val !== '')
})
export const addNewMemberForCovidPackage = Yup.object().shape({
    member_fname: Yup.string()
        .required(langContent.required_field),
    member_email: Yup.string()
        .required(langContent.required_field)
        .email(langContent.invalid_email),
    member_gender: Yup.string()
        .required(langContent.required_field),
    member_dob: Yup.string().required(langContent.required_field).test('dob', langContent.required_field, val => val !== '')
    ,
    member_idcard: Yup.string()
        .required(langContent.required_field),
})
export const bookAppointment = Yup.object().shape({
    member_id: Yup.string().required(langContent.required_field),
    appointment_mode: Yup.string().required(langContent.required_field)
})
export const addHealthPackageToCart = Yup.object().shape({
    package_date: Yup.string().required(langContent.required_field).test('package_date', langContent.required_field, val => val !== ''),
    member_id: Yup.string().required(langContent.required_field),
    sample_collect_time: Yup.string().required(langContent.required_field),
})
export const addMemberMedicalHistoryReportValidation = Yup.object().shape({
    // hospital_name: Yup.string().required(langContent.required_field),
    // doctor_name: Yup.string().required(langContent.required_field),
    // report_date: Yup.mixed().test('report_date', langContent.required_field, val => val !== ''),
    // file: Yup
    //     .mixed()
    //     .test('name', langContent.required_field, value => {
    //         return value[0] && value[0].name !== '';
    //     })
    //     .test('fileSize', langContent.the_file_is_too_large, value => {
    //         return value[0] && value[0].size <= 2000000;
    //     }),
})
export const addMemberMedicalHistoryReportMobileValidation = Yup.object().shape({
    // hospital_name: Yup.string().required(langContent.required_field),
    // doctor_name: Yup.string().required(langContent.required_field),
    // report_date: Yup.mixed().test('report_date', langContent.required_field, val => val !== ''),
    // medicalReport:Yup.string().required(langContent.required_field)
    // file: Yup
    //     .mixed()
    //     .test('name', langContent.required_field, value => {
    //         return value[0] && value[0].name !== '';
    //     })
    //     .test('fileSize', langContent.the_file_is_too_large, value => {
    //         return value[0] && value[0].size <= 2000000;
    //     }),
    
})
export const addPartnerValidation = Yup.object().shape({
    name: Yup.string()
        .required(langContent.required_field),
    email: Yup.string()
        .required(langContent.required_field)
        .email(langContent.invalid_email),
    mobile: Yup.string()
        .required(langContent.required_field)
        .test('len', langContent.mobile_number_validation, val => val && val.length >= 9 && val.length <= 11),
    description: Yup.string()
        .required(langContent.required_field)
})
export const bookVaccineValidation = Yup.object().shape({
    vaccinationCenter: Yup.string()
        .required(langContent.required_field),
    selectMember: Yup.string()
        .required(langContent.required_field),
    time: Yup.string()
        .required(langContent.required_field),
    vaccine_brand: Yup.string()
        .required(langContent.required_field),
    select_dose: Yup.string()
        .required(langContent.required_field),
    date_for_vaccination: Yup.mixed()
        .test('date_for_vaccination', langContent.required_field, val => val !== ''),
})
export const bookDose2Vaccination = Yup.object().shape({
    vaccinationCenter: Yup.string()
        .required(langContent.required_field),
    time: Yup.string()
        .required(langContent.required_field),
    date_for_vaccination: Yup.mixed()
        .test('date_for_vaccination', langContent.required_field, val => val !== ''),
    selectMember: Yup.string()
        .required(langContent.required_field),
})
export const addDeliveryAddressValidation = Yup.object().shape({
    customer_name: Yup.string()
        .required(langContent.required_field),
    address_phone1: Yup.string()
        .required(langContent.required_field)
        .test('len', langContent.mobile_number_validation, val => val && val.length >= 8 && val.length <= 11),
    address_line_1: Yup.string()
        .required(langContent.required_field),
    address_city_id: Yup.string()
        .required(langContent.required_field),
    address_district_id: Yup.string()
        .required(langContent.required_field),
    address_country: Yup.string()
        .required(langContent.required_field),
    address_type_id: Yup.string()
        .required(langContent.required_field),
    address_email: Yup.string()
        .required(langContent.required_field)
        .email(langContent.invalid_email),
})
export const addGuestInfoValidation = Yup.object().shape({
    customer_name: Yup.string()
        .required(langContent.required_field),
    address_phone1: Yup.string()
        .required(langContent.required_field)
        .test('len', langContent.mobile_number_validation, val => val && val.length >= 8 && val.length <= 11),
    address_line_1: Yup.string()
        .required(langContent.required_field),
    address_city_id: Yup.string()
        .required(langContent.required_field),
    address_country: Yup.string()
        .required(langContent.required_field),
    address_email: Yup.string()
        .required(langContent.required_field)
        .email(langContent.invalid_email),
})
export const contactUsValidation = Yup.object().shape({
    name: Yup.string()
        .required(langContent.required_field),
    mail: Yup.string()
        .required(langContent.required_field),
    mobile: Yup.string()
        .required(langContent.required_field),
    description: Yup.string()
        .required(langContent.required_field),

})
export const feedbackValidation = Yup.object().shape({
    feedback_text: Yup.string()
        .required(langContent.required_field),


})
export const bookHealthPackageValidation = Yup.object().shape({
    select_package: Yup.string().required(langContent.required_field),
    member_id: Yup.string().required(langContent.required_field),
    sample_collect_time: Yup.string().required(langContent.required_field),
    package_date: Yup.mixed().test('package_date', langContent.required_field, val => val !== '')
})