import { Button, makeStyles,CircularProgress } from '@material-ui/core'
import Text from '../components/typography/TextTypography';
import React from 'react'
import { useLanguage } from '../hooks/languageContext';
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({

    buttonStyle: {
        padding: '8px 22px'
    },
    alertBg:{
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '4px',
        boxShadow: 'none',
        fontWeight: '400',
        backgroundColor: 'rgb(229, 246, 253)',
        padding: '6px 16px',
        color: 'rgb(1, 67, 97)'
    }


}));

const ThemeAlert = ({ severity="info",content, ...rest }) => {
    const classes = useStyles();
    const { lang, langContent } = useLanguage()
    

    return (
        <Alert className={classes.alertBg}  severity={severity ? severity :'info'}>{content}</Alert>
    )
}

export default ThemeAlert