import React from 'react';
import MoonLoader from "react-spinners/MoonLoader";

const ThemeLoader = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '4rem' }}>
            <MoonLoader color="#36D7B7" size={45} />
        </div>
    )

}
export default ThemeLoader