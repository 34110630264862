import React from 'react';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import logo from "../assets/img/newlogo.png";
import { useLanguage } from '../hooks/languageContext';

const NewFooter = () => {
    const theme = useTheme();
    const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));
    const { lang, langContent } = useLanguage();

    return (
        <Box
            sx={{
                backgroundColor: '#0A5C5D',
                color: '#FFFFFF',
                padding: '50px 0 0px',
                display: matchesSMDown ? 'none' : 'block',
                marginTop:'3rem'
            }}
        >
            <Container maxWidth="xl">
                <Box sx={{
                    marginLeft: "100px",
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                        <img src={logo} alt="Victoria Healthcare Vietnam" style={{ width: '350px' }} />
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                {langContent.vic_health_int_clinic}
                            </Typography>
                            <List>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemIcon sx={{ minWidth: '30px', color: '#FFF' }}>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Branch 1: 37-39 Luong Dinh Cua, An Khanh Ward, Thu Duc City" sx={{ color: '#FFF' }} />
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemIcon sx={{ minWidth: '30px', color: '#FFF' }}>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Branch 2: 20-20Bis-22 Dinh Tien Hoang, Da Kao Ward, District 1" sx={{ color: '#FFF' }} />
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemIcon sx={{ minWidth: '30px', color: '#FFF' }}>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Branch 3: 135A Nguyen Van Troi, Ward 11, Phu Nhuan District" sx={{ color: '#FFF' }} />
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemIcon sx={{ minWidth: '30px', color: '#FFF' }}>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Branch 4: 1056 Nguyen Van Linh, Sky Garden 1, Phu My Hung, District 7" sx={{ color: '#FFF' }} />
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                             {langContent.contact_information}
                            </Typography>
                            <List>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemIcon sx={{ minWidth: '30px', color: '#FFF' }}>
                                        <PhoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Hotline: (028) 3910 4545"
                                        secondary="CSKH: (028) 73094545"
                                        sx={{ color: '#FFF' }}
                                        secondaryTypographyProps={{
                                            sx: { color: '#FFF' }
                                        }}
                                    />
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemIcon sx={{ minWidth: '30px', color: '#FFF' }}>
                                        <EmailIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Email: customer_support@victoriavn.com" sx={{ color: '#FFF' }} />
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemIcon sx={{ minWidth: '30px', color: '#FFF' }}>
                                        <LanguageIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Website: victoriavn.com" sx={{ color: '#FFF' }} />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Box sx={{
                backgroundColor: '#0A8B8C',
                display: matchesSMDown ? 'none' : 'block',
                padding: '10px 0px',
                marginTop: '20px'

            }}>
                <Typography variant="body2" sx={{ textAlign: 'center', color: '#FFF' }}>
                    ©2023 All rights reserved victoriavn.com
                </Typography>
            </Box>
        </Box>
    );
};

export default NewFooter;
