import React, { useContext, useState } from 'react'
import content from '../utils/language';
import { useLocation } from 'react-router';
const LanguageContext = React.createContext()
/**
 * ! This is a custom hook to get and change the user's language of choice
 * @returns 
 */
export const useLanguage = () => {
    return useContext(LanguageContext)
}
export const LanguageProvider = ({ children }) => {
    const location = useLocation()
    const urlPath = location.pathname
    const splitPath = urlPath.split('/')
    const urlLang = splitPath[splitPath.length - 1]
    if (urlLang === 'en' || urlLang === 'vn') {
        localStorage.setItem('lang', urlLang)
    } else {

        localStorage.getItem('lang') ? localStorage.setItem('lang', localStorage.getItem('lang')) : localStorage.setItem('lang', 'vn')
    }
    const userLang = localStorage.getItem('lang')
    const [lang, setLang] = useState(userLang)
    const [activeCity, setActiveCity] = useState(localStorage.getItem('setActiveCity') ? localStorage.getItem('setActiveCity') : '1')
    const [searchType, setSearchType] = useState(localStorage.getItem('geolocation') ? localStorage.getItem('geolocation') : 'city')
    const [lat, setLat] = useState(localStorage.getItem('lat') ? localStorage.getItem('lat') : 10.77259541);
    const [lng, setLng] = useState(localStorage.getItem('lng') ? localStorage.getItem('lng') : 106.67133331);
    localStorage.setItem('searchType', localStorage.getItem('searchType') ? localStorage.getItem('searchType') : 'city')
    localStorage.setItem('setActiveCity', localStorage.getItem('setActiveCity') ? localStorage.getItem('setActiveCity') : '1')
    const [searchValue, setSearchValue] = useState('')
    const [cartCount, setCartCount] = useState(null)
    let langContent = '' // This is Language object as per user's language choice
    lang === "vn"
        ? (langContent = content.vn)
        : (langContent = content.English);

    return (
        <LanguageContext.Provider value={{ lang, setLang, langContent, activeCity, setActiveCity, searchType, setSearchType, lat, setLat, lng, setLng, searchValue, setSearchValue, cartCount, setCartCount }}>
            {children}
        </LanguageContext.Provider>
    )
}